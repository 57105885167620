import Sticky from 'sticky-js';


// let scrollNav = new Sticky('.section__nav');

// product-overzicht

function stickyOnPageOverzicht(){
    const
        header = document.getElementsByClassName('layout__header'),
        subheader = document.querySelector('.product-overzicht__header'),
        backBlock = document.querySelector('.product-overzicht__back-container'),
        forSticky = document.querySelector('.product-overzicht__legend');

    let stickyBack, stickySub;

    if(!!header && !!forSticky){
        let hH = header[0].offsetHeight + backBlock.offsetHeight + subheader.offsetHeight - 35;
        const tablesHeads = document.querySelectorAll('.product-overzicht-page .thead');

        if(tablesHeads.length > 0){
            if(window.matchMedia("(min-width: 992px)").matches){
                for (let index = 0; index < tablesHeads.length; index++) {
                    const thead = tablesHeads[index];
                    thead.style.top = hH + 'px';
                }
            }
        }
        stickyBack = new Sticky('.product-overzicht__back-container', {
            stickyClass: 'is-sticky',
            marginTop: header[0].offsetHeight - 11,
            wrap: true,
            stickyContainer: '.product-overzicht'
        });

        stickySub = new Sticky('.product-overzicht__header', {
            stickyClass: 'is-sticky',
            marginTop: header[0].offsetHeight + backBlock.offsetHeight - 11,
            wrap: true,
            stickyContainer: '.product-overzicht'
        });
        window.addEventListener('resize', () => {
            stickyBack.update();
            stickySub.update();
        });
    }

}


stickyOnPageOverzicht();


// let stickySearchStatus = false;

// function stickyOnPageOverzicht(){
//     const
//         header = document.getElementById('header'),
//         forSticky = document.querySelector('.filterSearchLine');

//     let searchBarSticky;

//     if(!!header && !!forSticky && !stickySearchStatus){
//         let hH = header.offsetHeight;
//         searchBarSticky = new Sticky('.filterSearchLine',{
//             stickyClass: 'is-sticky',
//             marginTop: hH,
//             wrap: true
//         });
//         stickySearchStatus = true;
//     }

//     window.addEventListener('resize', () => {
//         searchBarSticky.update();
//     });

// }



const banner = document.querySelector('.made-right__banner.js-for-clone:not(.is-clone)');

if(!!banner){
    let bannerCloned = false,
        cloneBanner;
    function landingTitlePosition(){
        const
                bannerStartPosition = banner.getBoundingClientRect()['top'],
                targetPosition = window.innerHeight * .8;
        // console.log(bannerStartPosition);
        // console.log(targetPosition);
        if(bannerStartPosition < targetPosition){
            cloneBanner?.classList.remove('is-visible');
        } else {
            if(!bannerCloned){
                cloneBanner = banner.cloneNode(true);
                cloneBanner.classList.add('is-fixed');
                cloneBanner.classList.add('is-visible');
                banner.after(cloneBanner);
                bannerCloned = true;
            } else {
                cloneBanner.classList.add('is-visible');
            }
        }
    }


    window.addEventListener('scroll', landingTitlePosition);
    window.addEventListener('resize', landingTitlePosition);
    document.addEventListener("DOMContentLoaded", function(event) {
        landingTitlePosition()
    });
}


