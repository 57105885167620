if(document.getElementById('countdown')){
    function getTimeRemaining(endtime) {
        let t = Date.parse(endtime) - Date.parse(new Date());
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        let days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            'total': t,
            'days': days,
            'hours': hours,
            'minutes': minutes,
            'seconds': seconds
        };
    }
    
    function initializeClock(id, endtime) {
        const clock = document.getElementById(id),
              daysSpan = clock.querySelector('.days'),
              hoursSpan = clock.querySelector('.hours'),
              minutesSpan = clock.querySelector('.minutes'),
              secondsSpan = clock.querySelector('.seconds');
    
        function updateClock() {
            let t = getTimeRemaining(endtime);
    
            daysSpan.innerHTML = t.days;
            hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
            minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
            secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
    
            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }
    
        updateClock();
        let timeinterval = setInterval(updateClock, 1000);
    }
    
    let deadline="November 17 2021 20:00:00 GMT+0100";
    if (document.getElementById('countdown').classList.contains('countdown__phase2')){
        deadline = "December 15 2021 20:00:00 GMT+0100";
    }
    initializeClock('countdown', deadline);
}
