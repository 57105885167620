import $ from "jquery";
import {TweenLite} from 'gsap/all';



(function() {
    var throttle = function(type, name, obj) {
        obj = obj || window;
        var running = false;
        var func = function() {
            if (running) { return; }
            running = true;
             requestAnimationFrame(function() {
                obj.dispatchEvent(new CustomEvent(name));
                running = false;
            });
        };
        obj.addEventListener(type, func);
    };

    throttle("resize", "optimizedResize");
})();



function imgSize(item) {
    if(!!item){
        const   dummyImg = document.createElement('img'),
                dirtyUrl = $(item).css('background-image'),
                itemWidth = $(item).css('width'),
                itemMobHeight = item.scrollHeight + 80;
        let size = {};


        dummyImg.src = dirtyUrl.split(/"/)[1];
        dummyImg.classList.add('visibility-hidden');
        document.body.appendChild(dummyImg);
        $(dummyImg).css('width', itemWidth);

        size = dummyImg.clientHeight;

        dummyImg.remove();
        if (window.matchMedia("(max-width: 767px) and (orientation: portrait)").matches) {
            return itemMobHeight;
        } else {
            return size;
        }


    }
}

function getMaxGap(item){
    const   imgRealSize = imgSize(item),
            imgBlockSize = item.clientHeight;
    return imgBlockSize - imgRealSize < 0 ? (imgBlockSize - imgRealSize) : 0;
}

$(window).on('load', function() {
    const getOffsetLeft = (elem) => {
        var offsetLeft = 0;
        do {
            if (!isNaN(elem.offsetTop)) {
                offsetLeft += elem.offsetTop;
            }
        } while (elem = elem.offsetParent);
        return offsetLeft;
    };

    document.querySelectorAll(".scroll-el").forEach(item => {
        let itemTop = getOffsetLeft(item);
        let itemFactor = item.dataset.factor || '-0.1';
        let itemDirection = item.dataset.direction || false;
        let mediaStop = item.dataset.mobStop || false;

        window.addEventListener('scroll', function(e) {
            if(!!mediaStop && window.matchMedia(`(max-width: ${mediaStop}px)`).matches){
                TweenLite.killTweensOf(item);
            } else {
                if(!itemDirection){
                    TweenLite.to(item, 0.5, {
                        y: (window.scrollY - itemTop) * itemFactor,
                    });
                } else{
                    TweenLite.to(item, 0.5, {
                        x: (window.scrollY - itemTop) * itemFactor,
                    });
                }
            }
        });
    });

    // var $cats_visible = $('.assortiment__list').filter(function() { return $(this).css("display") != "none" });

    $('.assortiment__list .scroll-bg').each(function(){
        let item = this;
        let itemFactor = item.dataset.factor || '0.1';

        let maxGap = getMaxGap(item);

        window.addEventListener("optimizedResize", function() {
            maxGap = getMaxGap(item);
        });

        // console.log(maxGap);

        function setBG(item, itemFactor){
            TweenLite.fromTo(item, {
                    duration: .5,
                    backgroundPosition: 'center 0px',
                },{
                    // backgroundPosition: `center ${(window.scrollY - getOffsetLeft(item)) * itemFactor}px`,
                    backgroundPosition: `center -80px`,
                }
            );
        }
        // $(item).parent('.assortiment__list').onShow = function(item, itemFactor){
        //     setBG(item, itemFactor)
        // }

        $(function() {
            setBG(item, itemFactor);
        });


        window.addEventListener('scroll', function(e) {
            let newPosition;

            // console.log(maxGap);

            if(((window.scrollY - getOffsetLeft(item)) * itemFactor) > 0){
                newPosition = 0 + 'px';
            } else if(((window.scrollY - getOffsetLeft(item)) * itemFactor) < maxGap){
                newPosition = maxGap + 'px';
            } else {
                newPosition = ((window.scrollY - getOffsetLeft(item)) * itemFactor) + 'px';
            }

            TweenLite.to(item, 0.5, {
                backgroundPosition: `center ${newPosition}`,
            });
        });
    });
});
