import $ from "jquery";


$('.reg-modal__close').on('click', (e) => {
    $('body').removeClass('is-show-reg-modal');
    localStorage.setItem('showSubscribe', true);
});

$('.reg-modal__button a').on('click', (e) => {
    e.preventDefault();
    $('.reg-modal__subscribe').addClass('is-show');
});

$('.footer-subscribe__button a').on('click', (e) => {
    e.preventDefault();
    $('body').addClass('is-show-reg-modal_from-footer');
    $('.reg-modal__subscribe').addClass('is-show');
});

$('.reg-modal__subscribe__close, .reg-modal__overlay').on('click', (e) => {
    e.preventDefault();
    $('.reg-modal__subscribe').removeClass('is-show');
});


function addVisibilityToRegModal() {
    const regModal = $('.reg-modal');
    const showSubscribe = (localStorage.getItem("showSubscribe") === null) ? true : false;
    if (!regModal.length || !showSubscribe) {
        return;
    }


    let scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
    let positionUser = window.pageYOffset;


    setTimeout(() => {
        document.body.classList.add('is-show-reg-modal');
    }, 3000);
}

$(function () {
    addVisibilityToRegModal();
});


window.addEventListener('scroll', function () {
    addVisibilityToRegModal();
})
