import $ from "jquery";
import slick from 'slick-carousel';


function introSlider() {
    var time = 2;
    var $bar,
        $slick,
        isPause,
        tick,
        percentTime;

    $slick = $('#iSlider');
    $slick.slick({
        dots: true,
        infinite: true,
        speed: 1200,
        // autoplay: true,
        // autoplaySpeed: 4000,
        slidesToShow: 1,
        arrows: true,
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><svg><use xlink:href="#arrow-prev_1"></use></svg></button>',
        nextArrow: '<button class="slick-next" aria-label="Previous" type="button"><svg><use xlink:href="#arrow-next_1"></use></svg></button>',
        appendDots: $('.main-slider__dots .container'),
        fade: true,

    });

    $bar = $('.progress .line-progress');

    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        tick = setInterval(interval, 30);
    }

    function interval() {
        if (isPause === false) {
            percentTime += 1 / (time + 0.1);
            $bar.css({
                // width: percentTime + "%"
                'clip-path' : `polygon(0 0, ${percentTime}% 0, ${percentTime}% 100%, 0% 100%)`,
                '-webkit-clip-path' : `polygon(0 0, ${percentTime}% 0, ${percentTime}% 100%, 0% 100%)`
            });
            if (percentTime >= 100) {
                $slick.slick('slickNext');
                // startProgressbar();
            }
        }
    }

    function resetProgressbar() {
        $bar.css({
            // width: 0 + '%'
            'clip-path' : `polygon(0 0, ${0}% 0, ${0}% 100%, 0% 100%)`,
            '-webkit-clip-path' : `polygon(0 0, ${0}% 0, ${0}% 100%, 0% 100%)`

        });
        clearTimeout(tick);
    }

    startProgressbar();

    $slick.on('afterChange', function(){
        startProgressbar();
    })
}



$(function () {
    introSlider();
    addFzToTitle();
});

// remove class fz-smaller from main page product slider
function addFzToTitle(){
    let titles = $('.products-slider__title span');
    if (!titles.length) {
        return;
    }
    $.each(titles,function(i,e){
        let _that = $(e).text();
        if(_that.toString().length < 20){
            $(e).closest('.products-slider__title.fz-smaller').removeClass('fz-smaller');
        }
    })
}



$('#prodSlider').slick({
    dots: true,
    speed: 300,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><svg class="slick-arrow__img"><use xlink:href="#arrow-next_1"></use></svg></button>',
    nextArrow: '<button class="slick-next" aria-label="Next" type="button"><svg class="slick-arrow__img"><use xlink:href="#arrow-next_1"></use></svg></button>',
    appendDots: $('.products-slider__dots'),
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: false
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        }
    ]
});

if($('.history__slider')[0]){
    $('.history__slider').each(function(){
        $(this).slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            fade: true,
            // autoplay: true,
            autoplaySpeed: 4000,
            // pauseOnHover: false,
            arrows: false,
            // appendDots: $('.slider__dots'),
        });
    })
}

// $('.welcome-slider').slick({
//     arrows: false,
//     fade: true,
//     autoplay: true,
//     useTransform: true,
//     autoplaySpeed: 3000,
//     cssEase: 'ease-out',
//     dots: false
// });