import $ from "jquery";
import {TimelineMax, TweenMax, gsap, Power2} from 'gsap';
import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from 'gsap/all';


//phase 3 video block
let phase3Video = $('.phase3-video');
if(phase3Video.length > 0){
    const
        videoOverlay = $('.phase3-video__overlay'),
        video = $('video', videoOverlay),
        tlVideo = gsap.timeline({paused: true}),
        soundControl = $('.phase3-video__sound');

    let status = 0;


    if(video.length > 0 && videoOverlay.length > 0){
        console.log('ttt');
        let tlp = tlVideo
            .to('.phase3-video__play',{
                keyframes: [
                    {
                        scale: 1.1,
                        left: '5.5%',
                        bottom: '6%',
                        duration: .6,
                        ease: 'circ.in',
                    },
                    // {
                    //     opacity: 0,
                    //     duration: .2
                    // }
                ]
            })
            .to('.phase3-video__play .icon-play',{
                duration: .2,
                opacity: 0
            }, '>-.2')
            .to('.phase3-video__play .icon-stop',{
                duration: .2,
                opacity: 1
            }, '>-.2')
            .to('.phase3-video__sound',{
                keyframes: [
                    {
                        opacity: 1,
                        duration: .2
                    },
                    {
                        left: '10.5%',
                        duration: .4,
                        ease: 'back.out',
                        // ease: 'circ.in',
                        // ease: 'in',
                        // ease: 'back(1.7)',
                    }
                ]
            })

        $('.phase3-video__play').on('click', function(){
            videoOverlay.toggleClass('play-on');
            if(status === 0){
                video.get(0).play();
                video.addClass('is-visible');
                tlp.play();
                status = 1;
            } else {
                video.get(0).pause();
                tlp.reverse();
                video.removeClass('is-visible');
                status = 0;
            }
        })
    }


    if (soundControl.length > 0){
        soundControl.on('click', function(){
            if (video.get(0).muted === true) {
                video.get(0).muted = false;
                soundControl.addClass('_audio-on');
            } else {
                video.get(0).muted = true;
                soundControl.removeClass('_audio-on');
            }
        })
    }

}