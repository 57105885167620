import $ from "jquery";
// import './components/jquery.fullpage.extensions.min';
import {TimelineMax, TweenMax, gsap, Power2} from 'gsap';
import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from 'gsap/all';
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import 'fslightbox';
gsap.registerPlugin(MotionPathPlugin, EasePack, ScrollTrigger);


import imagesLoaded from 'imagesloaded';
import './components/sliders.js';
import './components/parallax';
import './components/product';
import './components/historyPage';
import './components/harmony';
import './components/single-product-animation';
import './components/product-info';
import './components/reg-modal';
import "./components/sticky";
import "./components/imgChanger";
import "./components/video";
import "./components/timerCountdown";

window.imagesLoaded = imagesLoaded;

// preloader
// const loaderWrap = document.querySelector('.preloader'),
//       loaderTxt = document.querySelector('.preloader h2');
// if(loaderWrap && document.body.classList.contains('main-page')){
//     function preloader() {
//         let preloaderCounter = sessionStorage.getItem("preloader") || 0,
//             tl = gsap.timeline();
//         const layout = document.querySelector('.layout');

//         if(preloaderCounter < 3){
//             loaderWrap.classList.remove('is-hidden');
//             document.body.classList.add('loaded_hiding');

//             tl
//                 .to(loaderWrap, { opacity: 1, duration: 1.2, ease: Power2.easeOut })
//                 .to(loaderTxt, { opacity: 1, duration: 1.5, delay: 0.35, ease: Power2.easeOut })
//                 .to(loaderWrap, { opacity: 0, duration: 1.5, delay: 2.65, ease: Power2.easeOut })
//                 .to(layout, { opacity: 1, duration: 1, ease: Power2.easeOut });

//             window.setTimeout(function () {
//                 document.body.classList.remove('loaded_hiding');
//                 document.body.classList.add('loaded');
//             }, 3000);
//             preloaderCounter++;
//             sessionStorage.setItem('preloader', preloaderCounter);
//         }
//     }
//     if(sessionStorage.getItem("preloader") >= 3){
//         loaderWrap.remove();
//         document.body.classList.add('loaded');
//     }
//     preloader();
// } else {
    // }
    // if(!document.body.classList.contains('main-page')){
        //     loaderWrap.remove();
        // }
        // \preloader
        
document.body.classList.add('loaded');
// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';
const body = document.querySelector('body');

// TweenMax.from($('.header'), 1, {opacity: 0, y: -100});


imagesLoaded(document.querySelector('.header'), () => {
    body.classList.add('load');
});
// imagesLoaded(body, () => {
//     body.classList.add('load');
// });
// document.onreadystatechange = function () {
//     if (document.readyState == "interactive") {
//         body.classList.add('load');
//     }
// }


$('.js-video .video-wrap__play').on('click', function(){
    let $parent = $(this).closest('.js-video'),
        video = $('.video', $parent),
        $cover = $('.video__cover', $parent);

    if (!video.length) {
        return;
    }
    $cover.hide();
    // console.log($video);
    video.get(0).play();
})


$('.toggler').on('click', function(){
    if($(this).hasClass('state1')){
        $(this).removeClass('state1').addClass('state2');
    } else {
        $(this).removeClass('state2').addClass('state1');
    }
});


function scrolltopShow(that){
    if ($(window).width() < 1023 &&
        $(that).scrollTop() > 0) {
        $('.scrolltotop').fadeIn();
    } else {
        $('.scrolltotop').fadeOut();
    }
}
scrolltopShow(this)
$('.scrolltotop').on('click', function(){
    $('body,html').animate({
        scrollTop: 0
    }, 400);
    return false;
});
$(window).scroll(function () {
    scrolltopShow(this)
});


class PagesAnimations {
    constructor() {
        function tween() {
            let
                tl = gsap.timeline(),
                headerWheat = $('.header .wheat'),
                headerLogo = $('.header .header__logo'),
                headerNavs = $('.header .nav__item'),
                headerTrigger = $('.mobile-trigger');

            TweenMax.set(headerWheat, {
                scale: 0.4,
                y: 20
            });
            TweenMax.set(headerLogo, {
                opacity: 0,
                xPercent: '-50'
            });
            TweenMax.set(headerTrigger, {
                opacity: 0,
                xPercent: '50'
            });
            TweenMax.set(headerNavs, {
                opacity: 0,
                yPercent: '-20'
            });

            // header animations
            tl
                .to([headerLogo,headerTrigger], {
                    delay: .7,
                    duration: .3,
                    xPercent: '0',
                    opacity: 1
                })
                .to(headerWheat, {
                    duration: .4,
                    scale: 1,
                    y: 0,
                    ease: 'back.out',
                    stagger: .1,
                }, '-=0.2')
                .to(headerNavs, {
                    duration: .6,
                    opacity: 1,
                    yPercent: '0',
                    ease: 'circ.out',
                    stagger: .05,
                }, '-=0.3')



            // all page animations

            // assortiment animations
            function assortiment() {
                let section = $('.assortiment');

                if (!section.length) {
                    return;
                }
                let
                    tl = gsap.timeline({delay: .2}),
                    wheat = $('.assortiment__decor-wheat', section);


                tl.from(wheat,
                    {
                        scale: 0.5,
                        opacity: 0,
                        xPercent: 10,
                        transformOrigin: '100% 100%',
                        ease: 'back(1.7)',
                        delay: 1
                    }

                )

            }

            // fade in from bottom
            $('.js-fade-up').each(function (i) {
                var tweenBlock = gsap.timeline();
                tweenBlock.from(this, {
                    duration: .4,
                    y: 50,
                    opacity: 0,
                    delay: (i % 6) * .15,
                    ease: 'circ.out'
                },'.8');
            });




            // index animations
            function index() {
                let section = $('.main-page');

                if (!section.length) {
                    return;
                }

                let
                    indexTl = gsap.timeline({delay: .2}),
                    intro = $('.intro', section);

                //set
                TweenMax.set(intro, {
                    opacity: 0,
                    scale: '.9',
                    transformOrigin: 'center'
                });

                // timeline
                indexTl
                    .to(intro, {
                        duration: .6,
                        scale: 1,
                        opacity: 1
                    }, '+=.8')

            }

            function landingProteineAnimation(){
                const   section = $('.protein-bar'),
                        wheat = $('.protein-bar__spikelet'),
                        img1 = $('.pb-image_1'),
                        img2 = $('.pb-image_2');
                if (!section.length && !wheat.length && !img1.length && !img2.length) {
                    return;
                }

                TweenMax.set(wheat, {
                    transformOrigin: '0 50%'
                });
                TweenMax.set(img2, {
                    transformOrigin: 'center'
                });

                gsap.timeline({
                    scrollTrigger: {
                      trigger: section,
                      start: "top 75%",
                      scrub: 1,
                      end: "bottom bottom",
                    //   onEnter: () => $(section).addClass('is-animated'),
                    },
                })
                .from(wheat, {
                    scale: 0.5,
                    y: 60,
                    // ease: 'back(1.7)',
                })
                .from(img1, {
                    yPercent: '-10',
                    duration: '.5',
                    ease: 'in'
                },"<")
                .from(img2, {
                    yPercent: '-9',
                    xPercent: '15',
                    rotate: 15,
                    duration: '.5',
                    ease: 'in'
                }, "<.1")

            }

            // function growOnScroll(){
            //     let target = $('.js-scrollAnimationWheat');

            //     if (!target.length) {
            //         return;
            //     }
            //     // console.log('test');

            //     TweenMax.set(target, {
            //         scale: 0,
            //         y: 60,
            //         transformOrigin: '0 50%'
            //     });

            //     gsap.timeline({
            //         scrollTrigger: {
            //           trigger: target,
            //         //   scrub: true,
            //           start: "top bottom",
            //           end: "top bottom"
            //         //   once: true
            //         }
            //     })
            //     .to(target, {
            //         scale: 1,
            //         y: 0,
            //         ease: 'back(1.7)',
            //     })

            // }


            // let's start party
            index();
            assortiment();
            // growOnScroll();

            landingProteineAnimation();

        }
        tween();
    }
}

new PagesAnimations();




class Menu {
    constructor() {
        this.el = document.querySelector('.js-menu');
        this.toggleCls = 'active';
        this.activeCls = 'open';

        if (this.el) {
            const openCtrl = document.querySelector('.js-open-menu');
            const closeCtrl = document.querySelector('.js-close-menu');

            openCtrl.addEventListener('click', event => {
                event.preventDefault();
                this.open();
                openCtrl.classList.add(this.toggleCls);
            });

            closeCtrl.addEventListener('click', event => {
                event.preventDefault();
                this.close();
                openCtrl.classList.remove(this.toggleCls);
            });
        }
    }

    open() {
        this.el.classList.add(this.activeCls);
    }

    close() {
        this.el.classList.remove(this.activeCls);
    }
}
new Menu();


var watchResize;
$(window).on('orientationchange resize load', function () {
    clearTimeout(watchResize);
    watchResize = setTimeout(function () {
        // addPaddingToIntro();
    }, 200);
});

if($('.media-page')[0]){
    let $mediaBtns = $('.media__tabBtn');
    $mediaBtns.on('click', function(){
        $mediaBtns.each(function(){
            $(this).removeClass('media__tab--active');
        })
        $('.media__content').each(function(){
            $(this).removeClass('-active');
        })
        $(this).addClass('media__tab--active')
        $('.media__content#' + $(this).data('id')).addClass('-active')
    })
}





// video with observer
const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            // console.log(entry.target);
            entry.target.play();
            // console.log('video is intersecting -- on');
        } else {
            entry.target.pause();
            // console.log('video out is intersecting -- off');
        }
    })
}, {
    threshold: 0.7
});

function autoplayVideos() {
    let videos = document.querySelectorAll('.js-video-autoplay');

    if (!videos.length) {
        return;
    }

    for (let i = 0; i < videos.length; i++) {
        let video = videos[i];
        let height = video.offsetHeight;
        let parent = video.closest('.js-video');
        let soundControl = parent.querySelector('.js-video-audio-control');


        if (!'IntersectionObserver' in window &&
        !'IntersectionObserverEntry' in window &&
        !'intersectionRatio' in window.IntersectionObserverEntry.prototype) {
            // console.log('not supported observer');
            video.play();
            // console.log('status video - on');
        } else {
            // console.log('push to observe');
            observer.observe(video);

        }

        if (!!soundControl){
            soundControl.addEventListener('click', function(){
                if (video.muted === true) {
                    video.muted = false;
                    soundControl.classList.add('_audio-on');
                } else {
                    video.muted = true;
                    soundControl.classList.remove('_audio-on');
                }
            })
        } else {
            console.log('element sound btn undefine');
        }
    }
}


document.addEventListener("DOMContentLoaded", function() {
    // console.log('begin autoplayvideo')
    autoplayVideos();
    // console.log('end autoplayvideo')
});



//crunch for lib fslightbox



function stopVideo() {
    // console.log('start fn stop video');
    let iframe = document.getElementsByClassName('fslightbox-youtube-iframe');

    // console.log(iframe);
    // console.log(fsLightbox)

    Array.prototype.forEach.call(iframe, function(el) {
        // console.log(el);
        const   videoVimeoContentWindow = el.contentWindow,
                videoVimeoTargetOriginUrl = el.src.split('?')[0],
                videoYoutubeTargetOriginUrl = el.src;
        if (videoVimeoTargetOriginUrl.indexOf('vimeo') > -1) {
            // console.log('vimeo stop');
            videoVimeoContentWindow.postMessage({
                'method': 'pause'
            }, videoVimeoTargetOriginUrl);
        } else if (videoYoutubeTargetOriginUrl.indexOf('youtube') > -1) {
            // console.log('youtube stop');
            el.src = videoYoutubeTargetOriginUrl.replace('&autoplay=1', '&autoplay=0');
        }
    });

}
function checkActiveVideo(){
    // console.log('Lightbox open!');
    let btnPrevNext = document.getElementsByClassName('fslightbox-slide-btn-container');
    Array.prototype.forEach.call(btnPrevNext, function(el) {
        el.addEventListener('click', stopVideo);
    });
}

if(document.body.classList.contains('media-page') && fsLightboxInstances['video-item']){
    fsLightboxInstances['video-item'].props.onOpen = () => {
        checkActiveVideo();
    };
}

//end crunch for lib fslightbox


