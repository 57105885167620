import $ from "jquery";
import slick from 'slick-carousel';
import mixitup from 'mixitup';
import mixitupMultifilter from './mixitup-multifilter.min.js';
import {TweenLite} from 'gsap/all';

let ingBtn = $('#js-ingredients');
let nutrionalBtn = $('#js-nutrional');
let modalClose = $('.modal .close');
const content = $('#content-container');
const adjustment = 10;
const assortSlider = $('.assortiment__slider');
const containerEl = $('#js-filter-container');
const scrollingBg = $('.scrol-bg');
mixitup.use(mixitupMultifilter);

function urlParam(name) {
    return (location.search.split(name + '=')[1] || '').split('&')[0];
}

if (containerEl.length > 0) {
    var mixer = mixitup(containerEl, {
        multifilter: {
            enable: true,
        },
        "animation": {
            "duration": 570,
            "nudge": false,
            "reverseOut": false,
            "effects": "fade translateY(100%) stagger(100ms)"
        },
        classNames: {
            block: 'assortiment__filter',
            delineatorElement: '-',
            delineatorModifier: '--'
        },
        callbacks: {
            onMixEnd: function() {
                if ($(window).width() > 1024) {
                    assortSlider.slick('refresh');

                    $('.assortiment__list .scroll-bg').each(function(){
                        TweenLite.to(this, .5, {
                            backgroundPosition: `center -80px`,
                        });
                    });
                }
            },
            onMixStart: function() {
                if ($(window).width() > 1024) {
                    // scrollingBg.addClass('opacity');
                }
            }
        }
    });
    if ($(window).width() < 1024) {
        mobileFilter();
    }

    let urlCats = urlParam('category');
    let cats = urlCats.length > 0 ? urlCats.split(',') : [];

    if(cats.length > 0){
        let cats_text = cats.length > 1 ? cats.join(', .') : cats[0];
        mixer.filter('.' + cats_text)
        cats.map((cat)=>{
            // mixer.filter('.' + cat)
            $('.assortiment__filters button[data-toggle=".'+ cat +'"]').click();
            // $('.assortiment__filters button[data-toggle="'+ cat +'"]').addClass('assortiment__filter-control--active');
        })
    }
}
function mobileFilter() {
    $('.assortiment__filter-btn').on('click', function (event) {
        $('.assortiment__filters').toggleClass('is-open');
    });
}

$('.assortiment__filter-btn').on('click', function (event) {
    let clickCat = $(this).data('toggle').substr(1);
    let cats = urlParam('category').length > 0 ? urlParam('category').split(',') : [];

    if(!$(this).hasClass('assortiment__filter-control--active')){
        // if need to add element
        if(cats.indexOf(clickCat) == -1){
            cats.push(clickCat);
        }
    } else {
        // if need to remove element
        if(cats.indexOf(clickCat) > -1){
            let cats_tmp = cats.slice();
            cats = cats_tmp.filter((itm)=>{
                return itm != clickCat;
            })
        }
    }

    let cats_text = cats.length > 0 ? '?category=' +  (cats.length > 1 ? cats.join(',') : cats[0]) : '';
    let url = new URL(window.location.href);
    window.history.replaceState(null, null, url.origin + url.pathname + cats_text)
});

// function scrollbg() {
//     scrollingBg.each(function() {
//         var scrolling = $(window).scrollTop();
//         var offset = $(this).offset().top;
//         var bgOffset = 50 - Math.round((offset - scrolling) * 0.05);
//         $(this).css('background-position', `50% ${bgOffset}%`);
//     });
// }

// $(window).on('scroll load', function() {
//     if ($(window).width() > 1024) {
//         scrollbg()
//     }
// });


assortSlider.on('init', function(event, slick, currentSlide){
    // if ($(window).width() > 1023 && slick.slideCount > 4) {
    //     $(this).addClass('slick-arrow--init');
    //     slick.options.slidesToShow = 3;
    // }
    // if(slick.slideCount < 3){
    //     $(this).addClass('align-left');
    // }

});



$('#down-btn').on('click',function(e){
    content.animate({
        scrollTop: $(e.currentTarget).position().top + content.scrollTop()
    },
    'slow');
});

ingBtn.on('click', function() {
    $('#modal-ingredients').addClass('shown');
    $('body').addClass('overflow-hidden');
});

nutrionalBtn.on('click', function() {
    $('#modal-nutrional').addClass('shown');
    $('body').addClass('overflow-hidden');
});

modalClose.on('click', function() {
    $(this).closest('.modal').removeClass('shown');
    $('body').removeClass('overflow-hidden');
});

let nutri_tg = document.querySelector('.nutri_score_toggler');
if(nutri_tg){
    nutri_tg.querySelector('.nutri_score_text').addEventListener('click', function(){
        this.style.display = 'none';
        this.parentElement.querySelector('.nutri_score').style.display = 'block';
    })

    nutri_tg.querySelector('.nutri_score').addEventListener('click', function(){
        this.style.display = 'none';
        this.parentElement.querySelector('.nutri_score_text').style.display = 'block';
    })
}


assortSlider.slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    autoplaySpeed: 4000,
    arrows: true,
    // prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><span class="slick-arrow__text">Terug</span><svg class="slick-arrow__img"><use xlink:href="#arrow-next_1"></use></svg></button>',
    // nextArrow: '<button class="slick-next" aria-label="Next" type="button"><svg class="slick-arrow__img"><use xlink:href="#arrow-next_1"></use></svg><span class="slick-arrow__text">Volgende</span></button>',
    prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><svg class="slick-arrow__img"><use xlink:href="#arrow-next_1"></use></svg></button>',
    nextArrow: '<button class="slick-next" aria-label="Next" type="button"><svg class="slick-arrow__img"><use xlink:href="#arrow-next_1"></use></svg></button>',
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                // arrows: false
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                // arrows: false
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
            }
        }
    ]
});

if (document.body.classList.contains('product-page')) {
    const prodBlock = document.querySelector('.product'),
          layoutBlock = document.querySelector('.layout__main');

    const minHeightMain = () => layoutBlock.setAttribute('style', 'min-height: ' + prodBlock.offsetHeight + 'px');

    minHeightMain();
    window.addEventListener('resize', () => {
        minHeightMain();
    });
}