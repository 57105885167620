import { gsap, TweenLite, TimelineMax, ScrollTrigger, Draggable, ScrollToPlugin } from 'gsap/all';
import imagesLoaded from "imagesloaded";

gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin);

let prLine, trigger, draggable;

if (document.body.classList.contains('history-page')) {
    // horizontal scroll
    const slides = document.querySelectorAll(".time-section"),
        // link = document.querySelector(".time-section__space-link"),
        scrolllink = document.querySelector(".global_space_link"),
        arrow = document.querySelector(".time-section__space-arrow"),
        container = document.querySelector(".horizontal-scroll"),
        slideImg = document.querySelectorAll(".time-section__img"),
        timelineItem = document.querySelectorAll(".timeline__year"),
        years = document.querySelectorAll(".time-section__year"),
        titles = document.querySelectorAll(".time-section__title"),
        timeline = document.querySelector(".timeline"),
        handler = document.querySelector(".timeline__scrollable");

        //main section
    const
        msTitle = document.querySelector('.main-section__title'),
        msLogo = document.querySelector('.main-section__logo'),
        msText = document.querySelector('.main-section .history-page__text'),
        msArray = [msTitle, msLogo, msText];

        // right text sections
    const elements = gsap.utils.toArray(document.querySelectorAll(".time-section > *"));


    let touchX = 0,
        triggerTouchStart = 0,
        triggerTouchEnabled = true,
        triggerTouchTimeout,
        slidesSumWidth = 0;

    const getOffsetLeft = (elem) => {
        var offsetLeft = 0;

        do {
            if (!isNaN(elem.offsetLeft)) {
                offsetLeft += elem.offsetLeft;
            }
        } while (elem = elem.offsetParent);
        return offsetLeft;
    };

    document.querySelector("body").addEventListener('touchstart', (e) => {
        touchX = e.touches[0].clientX;
        triggerTouchStart = trigger.scroll();
    }, false);

    document.querySelector("body").addEventListener("touchmove", (e) => {
        if (triggerTouchEnabled) {
            trigger.scroll(triggerTouchStart + (touchX - e.touches[0].clientX));
        }
    }, false);

    let init = () => {

        ScrollTrigger.matchMedia({
            "(min-width: 1024px)": function () {
                // let minusPercent;

                slides.forEach((item, index) => {
                    slidesSumWidth += item.offsetWidth;
                });

                let slideOffset = ((slidesSumWidth - window.innerWidth) / slides.length) / 20;

                // console.log(slideOffset/slidesSumWidth * 100 +);


                // minusPercent = window.innerWidth / slidesSumWidth;

                let positionTimeLineLabel = function(){
                    timelineItem.forEach((item, index) => {
                        item.style.left = ((getOffsetLeft(slides[index + 1]) / (slidesSumWidth - window.innerWidth + slideOffset)) * 100) + '%';
                    });
                }
                positionTimeLineLabel();



                // container.style.width = slidesSumWidth + 'px';

                titles.forEach((item, index) => {
                    item.innerHTML = item.textContent.replace(/[\s]/g, "#");
                    item.innerHTML = item.textContent.replace(/[\S]/g, "<span class='letter'>$&</span>");
                    item.innerHTML = '<div class="word">'+ item.innerHTML.replace(/[\#]/g, '<span class="letter space">&nbsp;</span></div><div class="word">') +'</div>';
                    // item.innerHTML = '<div class="word">'+ item.innerHTML.replace(/[\#]/g, '<span class="letter space">&nbsp;</span></div><div class="word">') +'</div>';
                });

                let tl = gsap.timeline().restart();

                tl.to(slides, {
                    // xPercent: -100 + minusPercent * 100,
                    x: () => `-${slidesSumWidth - window.innerWidth}`,
                    duration: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: container,
                        pin: true,
                        scrub: 0.5,
                        start: "top top",
                        end: () => container.scrollWidth - document.documentElement.clientWidth,
                        // timeline progress
                        onUpdate: self => {
                            // let tmProgress = +timeline.getAttribute('data-progress');

                            prLine = self.progress;

                            timeline.style.cssText = "--scroll:" + (prLine * 100) + "%";
                            timeline.setAttribute('data-progress', (prLine * 100).toFixed(0));
                            TweenLite.set(handler, {
                                x: prLine * timeline.offsetWidth,
                            });


                            timelineItem.forEach((item,index) => {
                                if (prLine * 100 >= (item.offsetLeft - 20) / timeline.offsetWidth * 100) {
                                    item.classList.add('is-active');
                                } else {
                                    item.classList.remove('is-active');
                                }
                            });

                            if(prLine > 0.005){
                                scrolllink.classList.add('dark');
                            } else {
                                scrolllink.classList.remove('dark');
                            }

                            if(timelineItem[timelineItem.length-1].classList.contains('is-active')){
                                scrolllink.style.opacity = 0;
                            } else {
                                scrolllink.style.opacity = 1;
                            }

                            if (trigger) {
                                // titles.forEach(item => {
                                //     if (!item.dataset.showed) {
                                //         if (trigger.scroll() + window.innerWidth > getOffsetLeft(item) + item.offsetWidth / 2) {
                                //             let letters = item.childNodes;

                                //             item.classList.add('is-showed');
                                //             letters.forEach((letter, lIndex) => {
                                //                 // console.log(letter);
                                //                 TweenLite.from(letter, 0.15, {
                                //                     opacity: "0",
                                //                     x: '-10px',
                                //                     scale: "0.5",
                                //                     delay: lIndex * 0.05,
                                //                 });
                                //             });
                                //             item.dataset.showed = '1';
                                //         }
                                //     }
                                // });
                                years.forEach(item => {
                                    let section = item.closest('.time-section');

                                    if (!item.dataset.showed) {
                                        if (trigger.scroll() + window.innerWidth > getOffsetLeft(item) + item.offsetWidth / 2) {
                                            item.classList.add('is-showed');
                                            item.dataset.showed = '1';
                                        }
                                    }

                                    TweenLite.to(item, 0.5, {
                                        x: ((trigger.scroll() + window.innerWidth / 2) - (getOffsetLeft(section) + section.offsetWidth / 2)) * -0.1,
                                    });
                                });
                            }
                        },
                    }
                });
                // animations
                elements.forEach((element, i) => {

                    const
                        texts = element.querySelector('.history-page__text'),
                        imgs = element.querySelector('.time-section__img');



                    // text
                    if(texts != null){

                        if(!texts.closest('.time-section').classList.contains('main-section')){


                            let start = element.closest('.time-section').dataset.animationText || null;
                            start = +start;
                            let startPointGap, startPoint;
                            if(start != 0){
                                startPointGap = (window.innerWidth * start) * -1;
                            } else {
                                startPointGap = 0;
                            }



                            startPoint = element.parentNode.offsetLeft + startPointGap;
                            // console.log('-----------' + i + '------------');
                            // console.log(texts);
                            // console.log(element.parentNode.offsetLeft);
                            // console.log(startPointGap);
                            // console.log(startPoint);
                            // console.log('-----------');


                            gsap.from(texts, {
                                yPercent: 15,
                                opacity: 0.5,
                                scrollTrigger: {
                                    scrub: 1,
                                    start: startPoint,
                                    end: '+=400',
                                    trigger: texts,
                                    // id: 'text_' + i,
                                    // markers: true,
                                    // onUpdate: self => {
                                    //     console.log(i);

                                    //     // if(i == 3){
                                    //     //     console.log(self.progress);
                                    //     //     console.log(element.parentNode.offsetLeft);
                                    //     // }
                                    // }
                                }
                            });
                        }
                    }


                    if(imgs != null){

                        if(!imgs.closest('.time-section').classList.contains('main-section')){


                            let start = element.closest('.time-section').dataset.animationText || null;
                            start = +start;
                            let startPointGap, startPoint;
                            if(start != 0){
                                startPointGap = (window.innerWidth * start) * -1;
                            } else {
                                startPointGap = 0;
                            }



                            startPoint = element.parentNode.offsetLeft + startPointGap;
                            // console.log('-----------' + i + '------------');
                            // console.log(texts);
                            // console.log(element.parentNode.offsetLeft);
                            // console.log(startPointGap);
                            // console.log(startPoint);
                            // console.log('-----------');


                            gsap.from(imgs, {
                                scale: .7,
                                opacity: 0.5,
                                scrollTrigger: {
                                    scrub: 1,
                                    start: startPoint,
                                    end: '+=400',
                                    trigger: imgs,
                                    // id: 'text_' + i,
                                    // markers: true,
                                    // onUpdate: self => {
                                    //     console.log(i);

                                    //     // if(i == 3){
                                    //     //     console.log(self.progress);
                                    //     //     console.log(element.parentNode.offsetLeft);
                                    //     // }
                                    // }
                                }
                            });
                        }
                    }

                });


                slides.forEach((sct, i) => {
                    let start = sct.dataset.animationText || null;
                        start = +start;
                        let startPointGap, startPoint;
                        if(start != 0){
                            startPointGap = (window.innerWidth * start) * -1;
                        } else {
                            startPointGap = 0;
                        }
                        startPoint = sct.parentNode.offsetLeft + startPointGap;

                    ScrollTrigger.create({
                        trigger: sct,
                        start: () => 'top top-=' + (sct.offsetLeft - window.innerWidth - sct.offsetLeft * 0.01 * i) * (slidesSumWidth / (slidesSumWidth - window.innerWidth)),
                        end: () => '+=' + sct.offsetWidth * (slidesSumWidth / (slidesSumWidth - window.innerWidth)),
                        //   toggleClass: {targets: sct, className: "active"}
                        once: true,
                        // onEnter: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
                        onEnter: () => {
                            // console.log(slides[i]);
                            if(!slides[i].classList.contains('main-section')){
                                let titles = slides[i].querySelectorAll('.time-section__title');
                                    titles.forEach(function(title){
                                        let letters = title.querySelectorAll('.letter');
                                        // console.log(letters);
                                        letters.forEach((letter, lIndex) => {
                                            // console.log(lIndex * 0.05);
                                            gsap.fromTo(letter,
                                                {
                                                    opacity: 0,
                                                    x: -10,
                                                    scale: 0.5,
                                                },
                                                {
                                                    duration: .15,
                                                    opacity: 1,
                                                    x: 0,
                                                    scale: 1,
                                                    delay: lIndex * 0.05,
                                                }
                                            );
                                        })
                                    })
                            }
                        },

                        // onUpdate: () => {
                        //     if(!slides[i].classList.contains('main-section')){
                        //         let year = slides[i].querySelector('.time-section__year');
                        //         console.log('tete');
                        //         gsap.to(year, {
                        //             duration: .5,
                        //             x: ((trigger.scroll() + window.innerWidth / 2) - (getOffsetLeft(slides[i]) + slides[i].offsetWidth / 2)) * -0.1,
                        //         });

                        //     }
                        // }
                    });
                });




                gsap.to(".time-section__space", {
                    scrollTrigger: {
                        trigger: "body",
                        start: "+=" + (window.innerWidth / 11),
                        end: "+=" + (window.innerWidth),
                        scrub: true,
                    },
                    width: "400px",
                    translateX: "-145px",
                    ease: "none"
                });
                gsap.to(arrow, {
                    scrollTrigger: {
                        trigger: "body",
                        start: "+=" + (window.innerWidth / 10),
                        end: "+=" + (window.innerWidth / 2),
                        scrub: true,
                    },
                    // opacity: 0,
                    ease: "none"
                });
                gsap.to(".main-section__image", {
                    scrollTrigger: {
                        trigger: "body",
                        start: "top top",
                        end: "",
                        scrub: 0.5,
                    },
                    x: "15%",
                    ease: "power1.out"
                });
                gsap.to(msArray, {
                    scrollTrigger: {
                        trigger: "body",
                        start: "top top",
                        scrub: 0.5,
                        endTrigger: '.time-section--1928',
                    },
                    opacity: 0,
                    scale: 0.5,
                    translateX: "-10vw",
                    skewX: "-=14deg",
                    ease: "power1.out",
                    transformOrigin: '100% 50%',
                    stagger: .15
                });

                // trSections.forEach((sct, i) => {
                //     var text = sct.querySelector(".history-page__text");
                //     console.log(text.parentNode);

                //     gsap.from(text, {
                //         yPercent: 15,
                //         opacity: 0.5,
                //         scrollTrigger: {
                //             scrub: 1,
                //             start: text.parentNode.offsetLeft,
                //             trigger: text.parentNode,
                //             // onUpdate: self => {
                //             //     prLine = self.progress;
                //             //     console.log(prLine + ' -name');
                //             //     console.log(text.closest('.time-section'));
                //             // }
                //         }
                //     })


                // });

                // drag scroll
                trigger = ScrollTrigger.create({});
                draggable = Draggable.create(handler, {
                    type: "x",
                    bounds: timeline,
                    onDrag: function () {
                        // trigger.scroll((this.x / timeline.offsetWidth) * slidesSumWidth * (1 - minusPercent));
                        trigger.scroll((this.x / timeline.offsetWidth) * slidesSumWidth)
                    },
                    onDragStart: function () {
                        triggerTouchEnabled = false;
                    },
                    onDragEnd: function () {
                        triggerTouchEnabled = true;
                    },
                })[0];

                window.addEventListener('resize',function(){
                    document.location.reload();
                    trigger.scroll(0);
                })

                document.querySelector(".timeline__start").addEventListener('click', (e) => {
                    e.preventDefault();
                    // trigger.scroll(((item.offsetLeft) / timeline.offsetWidth) * slidesSumWidth  * (1 - minusPercent));
                    // console.log(((slidesSumWidth - window.innerWidth) / slides.length) / 20);
                    trigger.scroll(0);
                });

                timelineItem.forEach((item, i) => {
                    item.addEventListener('click', (e) => {
                        e.preventDefault();
                        // trigger.scroll(((item.offsetLeft) / timeline.offsetWidth) * slidesSumWidth  * (1 - minusPercent));
                        // console.log(((slidesSumWidth - window.innerWidth) / slides.length) / 20);
                        trigger.scroll(slides[i+1].offsetLeft - slideOffset);
                    });
                });

                scrolllink.addEventListener('click', (e) => {
                    e.preventDefault();

                    let activeElInd = 0;
                    for(let i=0; i < timelineItem.length; i++){
                        if(!timelineItem[i].classList.contains('is-active')){
                            activeElInd = prLine < .08 ? 0 : i;
                            trigger.scroll(slides[activeElInd + 1].offsetLeft - slideOffset);
                            // trigger.scroll(((timelineItem[activeElInd].offsetLeft) / timeline.offsetWidth) * slidesSumWidth  * (1 - minusPercent));
                            break;
                        }
                    }

                    setTimeout(function(elm = timelineItem[activeElInd]){
                        if(timelineItem[timelineItem.length-2].classList.contains('is-active')){
                            scrolllink.style.opacity = 0;
                        } else {
                            scrolllink.style.opacity = 1;
                        }
                    },200);
                });
            },
            "(max-width: 1023px)": function () {
                gsap.to(".main-section .row", {
                    scrollTrigger: {
                        trigger: "body",
                        start: "top top",
                        scrub: true,
                    },
                    opacity: 0,
                    scale: 0.5,
                    translateY: "-150%",
                    ease: "power1.out"
                });
            },
            "all": function () {
                gsap.set(".main-section .container", {opacity: 1, scale: 1})
                gsap.fromTo(msArray,
                    {
                        opacity: 0,
                        scale: .5,
                    },
                    {
                        duration: .6,
                        opacity: 1,
                        scale: 1,
                        stagger: .2,
                        ease: 'back(1.2)',
                        delay: 0.4,
                    }
                );
            }
        });
    };

    imagesLoaded(document.querySelector('body'), () => {
        init();
    });
}