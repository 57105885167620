import $ from "jquery";
import imagesLoaded from 'imagesloaded';
import {TimelineMax, TweenMax, gsap} from 'gsap';
import { EasePack } from "gsap/EasePack";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
gsap.registerPlugin(MotionPathPlugin, EasePack);


class BrandsAnimations {
    constructor() {
        function tween() {

            // BELVITA_Breakfast_chocolade (1)
            function belvita_breakfast_chocolade() {
                let section = $('.visual-belvita-bc');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita1 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvita1Cookie1 = $('.visual-belvita-bc__cookie1'),
                    belvita1Cookie2 = $('.visual-belvita-bc__cookie2'),
                    belvita1Cookie3 = $('.visual-belvita-bc__cookie3'),
                    belvita1Cookie4 = $('.visual-belvita-bc__cookie4'),
                    belvita1BG      = $('.visual-belvita-bc__bg'),
                    belvita1Decor1 = $('.visual-belvita-bc__grain-top'),
                    belvita1Decor2 = $('.visual-belvita-bc__grain-bottom'),
                    belvita1Decor3 = $('.visual-belvita-bc__choco-top'),
                    belvita1Decor4 = $('.visual-belvita-bc__choco-bottom'),
                    belvita1Decor5 = $('.visual-belvita-bc__wheat');


                // let choco = [belvita1Decor4, belvita1Decor3];
                let drops = [belvita1Decor2, belvita1Decor1];
                let wheat = belvita1Decor5;

                // Set
                TweenMax.set(belvita1BG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set([belvita1Cookie3, belvita1Cookie2, belvita1Cookie1, belvita1Cookie4], {
                    scale: 0,
                    y: 40
                });
                TweenMax.set(wheat, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvita1Decor4, {
                    scale: 0,
                    y: -40
                });
                TweenMax.set(belvita1Decor3, {
                    scale: 0,
                    y: 40
                });
                TweenMax.set(drops, {
                    scale: 0,
                    y: 20
                });

                // timeline
                tlBrandBelvita1
                    .to(belvita1BG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .staggerTo([belvita1Cookie4, belvita1Cookie1, belvita1Cookie2, belvita1Cookie3], .4, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    }, 0.15)
                    .staggerTo([belvita1Decor3,belvita1Decor4], .2, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    }, 0.1, 1.4)
                    .to(wheat, .3, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, 1.3)
                    .staggerTo(drops, .3, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, 0.15, 1.4)

                    ///add decors
            }

            // BELVITA_Breakfast_Honing-en-hazelnoot (2)
            function belvita_breakfast_honing() {
                let section = $('.visual-belvita-bh');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita2 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvita1Cookie1 = $('.visual-belvita-bh__cookie1'),
                    belvita1Cookie2 = $('.visual-belvita-bh__cookie2'),
                    belvita1Cookie3 = $('.visual-belvita-bh__cookie3'),
                    belvita1Cookie4 = $('.visual-belvita-bh__cookie4'),
                    belvita1BG      = $('.visual-belvita-bh__bg'),
                    belvita1Decor1 = $('.visual-belvita-bh__decor1'),
                    belvita1Decor2 = $('.visual-belvita-bh__decor2'),
                    belvita1Decor3 = $('.visual-belvita-bh__decor3'),
                    belvita1Decor4 = $('.visual-belvita-bh__decor4');


                let cookies = [belvita1Cookie1, belvita1Cookie2, belvita1Cookie3, belvita1Cookie4];
                let decor = [belvita1Decor1, belvita1Decor2, belvita1Decor3, belvita1Decor4];

                // Set
                TweenMax.set(belvita1BG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    scale: 0,
                    y: 40
                });
                TweenMax.set(decor, {
                    scale: 0,
                    y: 20
                });


                // timeline
                tlBrandBelvita2
                    .to(belvita1BG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .staggerTo(cookies, .4, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, 0.15)
                    .staggerTo(decor, .2, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, 0.1, 1.2)


                    ///add decors
            }

            // BELVITA_Breakfast_Meergranen (3)
            function belvita_breakfast_meergranen() {
                let section = $('.visual-belvita-m');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita3 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvita1Cookie1 = $('.visual-belvita-m__cookie1'),
                    belvita1Cookie2 = $('.visual-belvita-m__cookie2'),
                    belvita1Cookie3 = $('.visual-belvita-m__cookie3'),
                    belvita1Cookie4 = $('.visual-belvita-m__cookie4'),
                    belvita1BG      = $('.visual-belvita-m__bg'),
                    belvita1Decor1 = $('.visual-belvita-m__decor1'),
                    belvita1Decor2 = $('.visual-belvita-m__decor2'),
                    belvita1Decor3 = $('.visual-belvita-m__wheat1'),
                    belvita1Decor4 = $('.visual-belvita-m__wheat2');


                let cookies = [belvita1Cookie1, belvita1Cookie2, belvita1Cookie3, belvita1Cookie4];
                let decor = [belvita1Decor1, belvita1Decor2];
                let wheat = [belvita1Decor3, belvita1Decor4];

                // Set
                TweenMax.set(belvita1BG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    scale: 0,
                    y: 40
                });
                TweenMax.set(decor, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(wheat, {
                    scale: 0,
                    y: 60
                });


                tlBrandBelvita3
                    .to(belvita1BG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .staggerTo(cookies, .4, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    }, 0.15)
                    .staggerTo(decor, .2, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    }, 0.1, 1.2)
                    .staggerTo(wheat, .3, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, 0.05, '-=0.1');


            }

            // BELVITA_Breakfast_Melk en granen (4)
            function belvita_breakfast_melk_en_granen() {
                let section = $('.visual-belvita-mg');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita4 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvitaCookie1 = $('.visual-belvita-mg__cookie1'),
                    belvitaCookie2 = $('.visual-belvita-mg__cookie2'),
                    belvitaCookie3 = $('.visual-belvita-mg__cookie3'),
                    belvitaCookie4 = $('.visual-belvita-mg__cookie4'),
                    belvitaBG      = $('.visual-belvita-mg__bg'),
                    belvitaDecor1 = $('.visual-belvita-mg__decor1'),
                    belvitaDecor2 = $('.visual-belvita-mg__decor2'),
                    belvitaDecor3 = $('.visual-belvita-mg__milk'),
                    belvitaDecor4 = $('.visual-belvita-mg__wheat');


                let cookies = [belvitaCookie1, belvitaCookie2, belvitaCookie3, belvitaCookie4];
                let decor = [belvitaDecor1, belvitaDecor2];
                let wheat = belvitaDecor4;

                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    scale: 0,
                    y: 40
                });
                TweenMax.set(decor, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(wheat, {
                    scale: 0,
                    y: 60
                });
                TweenMax.set(belvitaDecor3, {
                    scale: 0,
                    y: 20
                });


                tlBrandBelvita4
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1)'
                    })
                    .staggerTo(cookies, .4, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    }, 0.15)
                    .staggerTo(decor, .2, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    }, 0.1, 1.2)
                    .staggerTo(wheat, .3, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, 0.05, '-=0.1')
                    .to(belvitaDecor3, .3, {
                        scale: 1,
                        x: 0,
                        ease: 'back(1.7)'
                    }, '-=0.1')


            }

            // BELVITA_Sandwich_Yoghurt-Aardbei (5)
            function belvita_sandwich_yoghurt_aardbei() {
                let section = $('.visual-belvita-sy');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita5 = new TimelineMax({delay: .2}).timeScale(.6),
                    belvitaCookie = $('.visual-belvita-sy__cookie'),
                    belvitaCookiePart1 = $('.visual-belvita-sy__cookie-part1'),
                    belvitaCookiePart2 = $('.visual-belvita-sy__cookie-part2'),
                    belvitaBG      = $('.visual-belvita-sy__bg'),
                    belvitaStrawberry = $('.visual-belvita-sy__strawberry');


                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaCookie, {
                    opacity: 1,
                    scale: 0,
                    y: 50
                });
                TweenMax.set(belvitaCookiePart1, {
                    opacity: 0,
                    translateX: '10%',
                    translateY: '-4%'
                });
                TweenMax.set(belvitaCookiePart2, {
                    opacity: 0,
                    rotate: '-12deg'
                });
                TweenMax.set(belvitaStrawberry, {
                    opacity: 0,
                    translateX: '14%',
                    translateY: '18%',
                    scale: 0.5
                });


                tlBrandBelvita5
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(belvitaCookie, .6,{
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, '-=.2')
                    .set(belvitaCookie, {
                        opacity: 0,
                        // ease: 'expo.out'
                    }, '-=.1')
                    .set([belvitaCookiePart1, belvitaCookiePart2], {
                        opacity: 1,
                        // ease: Sine.easeOut
                    }, '-=.1')
                    .to(belvitaCookiePart1, .6,{
                        translateX: 0,
                        translateY: 0,
                        ease: 'expo.out'
                    }, '-=.2')
                    .to(belvitaCookiePart2, .6,{
                        translateX: '10%',
                        translateY: '-7%',
                        rotate: '22deg',
                        ease: 'expo.out'
                    }, '-=.5')
                    .to(belvitaStrawberry, .4,{
                        translateX: 0,
                        translateY: 0,
                        scale: 0.89,
                        opacity: 1,
                        ease: 'circ.out'
                    }, '-=.5')

            }

            // BELVITA_Soft_bakes_Chocolade-met-5-granen_v1 (6)
            function belvita_bakes_chocolade_5granen_v1() {
                let section = $('.visual-belvita-sc1');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita6 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvitaCookie = $('.visual-belvita-sc1__cookie'),
                    belvitaCookiePart1 = $('.visual-belvita-sc1__cookie-part1'),
                    belvitaCookiePart2 = $('.visual-belvita-sc1__cookie-part2'),
                    belvitaBG      = $('.visual-belvita-sc1__bg'),
                    belvitaChoco1 = $('.visual-belvita-sc1__choco1'),
                    belvitaChoco2 = $('.visual-belvita-sc1__choco2');


                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaCookie, {
                    opacity: 1,
                    rotate: '-30deg',
                    // scale: 0,
                    y: 100
                });
                TweenMax.set(belvitaCookiePart1, {
                    opacity: 0,
                    transformOrigin: '8% 27%'
                });
                TweenMax.set(belvitaCookiePart2, {
                    opacity: 0,
                    transformOrigin: '70% 0',
                    rotate: '-21deg'
                });
                TweenMax.set(belvitaChoco1, {
                    opacity: 0,
                    scale: 0.5,
                    xPercent: 30,
                    yPercent: 30,
                });
                TweenMax.set(belvitaChoco2, {
                    opacity: 0,
                    scale: 0.5,
                    xPercent: -20,
                    yPercent: -20
                });


                tlBrandBelvita6
                    //first step
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(belvitaCookie, .6,{
                        scale: 1,
                        rotate: '18deg',
                        y: 0,
                        xPercent: 10,
                        ease: 'back(1.7)'
                    }, '-=.2')
                    .set(belvitaCookie, {
                        opacity: 0,
                    }, '-=.1')
                    .set([belvitaCookiePart1, belvitaCookiePart2], {
                        opacity: 1,
                    }, '-=.1')

                    .to(belvitaCookiePart1, .6,{
                        rotate: '-13deg',
                        ease: 'expo.out'
                    }, '-=.2')
                    .to(belvitaCookiePart2, .6,{
                        rotate: '-21deg',
                        ease: 'expo.out'
                    }, '-=.5')
                    .to(belvitaChoco1, .5,{
                        opacity: 1,
                        scale: 1,
                        xPercent: 0,
                        yPercent: 0,
                        ease: 'circ.out'
                    }, '-=.6')
                    .to(belvitaChoco2, .5,{
                        opacity: 1,
                        scale: 1,
                        xPercent: 0,
                        yPercent: 0,
                        ease: 'circ.out'
                    }, '-=.6')

            }

            // BELVITA_Soft_bakes_Chocolade-met-5-granen_v2 (7)
            function belvita_bakes_chocolade_5granen_v2() {
                let section = $('.visual-belvita-sc2');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita7 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvitaCookie = $('.visual-belvita-sc2__cookie'),
                    belvitaCookiePart1 = $('.visual-belvita-sc2__cookie-part1'),
                    belvitaCookiePart2 = $('.visual-belvita-sc2__cookie-part2'),
                    belvitaBG      = $('.visual-belvita-sc2__bg'),
                    belvitaDrops = $('.visual-belvita-sc2__drops');


                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaCookie, {
                    opacity: 0,
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaCookiePart1, {
                    opacity: 0,
                });
                TweenMax.set(belvitaCookiePart2, {
                    opacity: 0,
                });
                TweenMax.set(belvitaDrops, {
                    opacity: 0,
                    scale: 0.5,
                    xPercent: 63,
                    yPercent: 50,
                });

                tlBrandBelvita7
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(belvitaCookie, .6,{
                        scale: 1.2,
                        opacity: 1,
                        ease: 'back(1.7)'
                    }, '-=.2')
                    .set(belvitaCookie, {
                        opacity: 0,
                    })
                    .set([belvitaCookiePart1, belvitaCookiePart2], {
                        opacity: 1,
                    })

                    .to(belvitaCookiePart1, .6,{
                        rotate: '7deg',
                        translateX: '2%',
                        translateY: '-5%',
                        ease: 'back(1.4)'
                    })
                    .to(belvitaCookiePart2, .6,{
                        rotate: '-1deg',
                        translateX: '-2%',
                        ease: 'back(1.4)'
                    }, '-=.6')
                    .to(belvitaDrops, .4,{
                        opacity: 1,
                        scale: 1,
                        xPercent: 0,
                        yPercent: 0,
                        ease: 'circ.out'
                    }, '-=.6')

            }

            // CRACOTTE_5-granen (8)
            function cracotte_5_granen() {
                let section = $('.visual-cracotte-5g');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita8 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvitaStage1 = $('.visual-cracotte-5g__stage1'),
                    belvitaStage2 = $('.visual-cracotte-5g__stage2'),
                    belvitaStage3 = $('.visual-cracotte-5g__stage3'),
                    belvitaBG      = $('.visual-cracotte-5g__bg');


                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaStage1, {
                    opacity: 0,
                    scale: 0,
                    y: 20,
                    rotate: -10
                });
                TweenMax.set([belvitaStage2, belvitaStage3], {
                    opacity: 0,

                });
                TweenMax.set(belvitaStage2,{
                    rotate: 37,
                    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
                });
                TweenMax.set(belvitaStage3,{
                    rotate: 95,
                    scale: 0
                });

                tlBrandBelvita8
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(belvitaStage1, .4, {
                        scale: 1,
                        opacity: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    })
                    .to([belvitaStage1, belvitaStage2, belvitaStage3], 1.7, {
                        rotate: '-=75',
                        // ease: 'power1.out'
                    })

                    .to(belvitaStage2, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)"
                            }
                        ]
                    },'<-.1')
                    .to(belvitaStage3, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                scale: 1,
                                ease: 'back(1.7)'
                            }
                        ]
                    },'<.5')


            }

            // CRACOTTE_naturel (9)
            function cracotte_naturel() {
                let section = $('.visual-cracotte-n');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita9 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvitaStage1 = $('.visual-cracotte-n__stage1'),
                    belvitaStage2 = $('.visual-cracotte-n__stage2'),
                    belvitaStage3 = $('.visual-cracotte-n__stage3'),
                    belvitaStage4 = $('.visual-cracotte-n__stage4'),
                    belvitaBG      = $('.visual-cracotte-n__bg');

                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaStage1, {
                    opacity: 0,
                    scale: 0,
                    y: 20,
                    rotate: -10
                });
                TweenMax.set([belvitaStage2, belvitaStage3, belvitaStage4], {
                    opacity: 0,

                });
                TweenMax.set(belvitaStage2,{
                    rotate: 13,
                    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
                });
                TweenMax.set(belvitaStage3,{
                    rotate: 44,
                    scale: 0
                });
                TweenMax.set(belvitaStage4,{
                    rotate: 88,
                    z: 300
                });
                TweenMax.set(section,{
                    perspective: 1000
                });



                tlBrandBelvita9
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(belvitaStage1, .4, {
                        scale: 1,
                        opacity: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    })
                    .to([belvitaStage1, belvitaStage2, belvitaStage3, belvitaStage4], 1.9, {
                        rotate: '-=88',
                        // ease: 'power1.out'
                    })

                    .to(belvitaStage2, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)"
                            }
                        ]
                    },'<-.1')
                    .to(belvitaStage3, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                scale: 1,
                                ease: 'back(1.7)'
                            }
                        ]
                    },'<.5')
                    .to(belvitaStage4, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                z: 0,
                                ease: 'back(1.7)'
                            }
                        ]
                    },'<.5')

            }

            // CRACOTTE_volkoren (10)
            function cracotte_volkoren() {
                let section = $('.visual-cracotte-v');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandBelvita10 = new TimelineMax({delay: .2}).timeScale(.7),
                    belvitaStage1 = $('.visual-cracotte-v__stage1'),
                    belvitaStage2 = $('.visual-cracotte-v__stage2'),
                    belvitaStage3 = $('.visual-cracotte-v__stage3'),
                    belvitaStage4 = $('.visual-cracotte-v__stage4'),
                    belvitaBG      = $('.visual-cracotte-v__bg');


                // Set
                TweenMax.set(belvitaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(belvitaStage1, {
                    opacity: 0,
                    scale: 0,
                    y: 20,
                    rotate: -10
                });
                TweenMax.set([belvitaStage2, belvitaStage3, belvitaStage4], {
                    opacity: 0,

                });
                TweenMax.set(belvitaStage2,{
                    rotate: -30,
                    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
                });
                TweenMax.set(belvitaStage3,{
                    rotate: -63,
                    scale: 0
                });
                TweenMax.set(belvitaStage4,{
                    rotate: -91,
                    z: 300
                });
                TweenMax.set(section,{
                    perspective: 1000
                });



                tlBrandBelvita10
                    .to(belvitaBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(belvitaStage1, .4, {
                        scale: 1,
                        opacity: 1,
                        y: 0,
                        ease: 'back(1.4)'
                    })
                    .to([belvitaStage1, belvitaStage2, belvitaStage3, belvitaStage4], 1.9, {
                        rotate: '+=91',
                        // ease: 'power1.out'
                    })

                    .to(belvitaStage2, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)"
                            }
                        ]
                    },'<-.1')
                    .to(belvitaStage3, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                scale: 1,
                                ease: 'back(1.7)'
                            }
                        ]
                    },'<.5')
                    .to(belvitaStage4, {
                        keyframes: [
                            {
                                duration: .1,
                                opacity: 1
                            },
                            {
                                duration: .4,
                                z: 0,
                                ease: 'back(1.7)'
                            }
                        ]
                    },'<.5')

            }

            // LiGA_BabyBiscuits (11)
            function liga_babybiscuits() {
                let section = $('.visual-liga-bb');

                if (!section.length) {
                    return;
                }

                let
                    tlBrandLiga1 = new TimelineMax({delay: .2}).timeScale(.8),
                    ligaCookie1 = $('.visual-liga-bb__cookie1'),
                    ligaCookie2 = $('.visual-liga-bb__cookie2'),
                    ligaBG      = $('.visual-liga-bb__bg');

                // Set
                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(section, {
                    perspective: 100
                });
                TweenMax.set([ligaCookie1, ligaCookie2], {
                    opacity: 0,
                    scale: 0,
                });
                TweenMax.set(ligaCookie1, {
                    xPercent: -5,
                    rotate: -47
                })
                TweenMax.set(ligaCookie2, {
                    xPercent: 7,
                    rotate: -28
                })

                // var path1 = [
                //     {xPercent:'0%', yPercent:'0%'},
                //     {xPercent:'-4%', yPercent:'-11%'},
                //     {xPercent:'-11%', yPercent:'-23%'},
                //     {xPercent:'-18%', yPercent:'-37%'}
                // ]
                // var path2 = [
                //     {xPercent:'0%', yPercent:'0%'},
                //     {xPercent:'7%', yPercent:'-15%'},
                //     {xPercent:'13%', yPercent:'-27%'},
                //     {xPercent:'17%', yPercent:'-43%'}
                // ]


                tlBrandLiga1
                .to(ligaBG, {
                    duration: .5,
                    scale: 1,
                    y: 0,
                    ease: 'back(1.2)'
                })
                .to([ligaCookie1, ligaCookie2], {
                    duration: .6,
                    scale: 1,
                    opacity: 1,
                    y: 0,
                    ease: 'back(2.7)',
                    stagger: 0.15
                })

                .to(ligaCookie1,
                    {
                        duration: 1,
                        ease: 'power3.easeOut',
                        rotate: -65,
                        xPercent: -18,
                        yPercent: -37
                    }, '-=0.2'

                )
                .to(ligaCookie2,
                    {
                        duration: 1,
                        ease: 'power3.easeOut',
                        rotate: -4,
                        xPercent: 17,
                        yPercent: -43
                    },'-=.9'
                )

            }

            // LIGA_Crunchy_Muesli-Appel (12)
            function liga_crunchy_muesli_appel() {
                let section = $('.visual-liga-cma');

                if (!section.length) {
                    return;
                }
                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaCrunchy = $('.visual-liga-cma__cookie'),
                    ligaCrunchyPart1 = $('.visual-liga-cma__cookie-part1'),
                    ligaCrunchyPart2 = $('.visual-liga-cma__cookie-part2'),
                    ligaBG     = $('.visual-liga-cma__bg'),
                    ligaDecor = $('.visual-liga-cma__decor');


                // Set
                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaCrunchy, {
                    opacity: 1,
                    scale: 0,
                    y: 50
                });
                TweenMax.set(ligaCrunchyPart1, {
                    opacity: 0,
                    translateX: '10%',
                    translateY: '-4%'
                });
                TweenMax.set(ligaCrunchyPart2, {
                    opacity: 0,
                    rotate: '-12deg'
                });
                TweenMax.set(ligaDecor, {
                    opacity: 0,
                    translateX: '14%',
                    translateY: '18%',
                    scale: 0.5
                });


                tl
                    .to(ligaBG, {
                        duration: .6,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaCrunchy, {
                        duration: .6,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, '-=.2')
                    .set(ligaCrunchy, {
                        opacity: 0,
                        // ease: 'expo.out'
                    }, '-=.1')
                    .set([ligaCrunchyPart1, ligaCrunchyPart2], {
                        opacity: 1,
                        // ease: Sine.easeOut
                    }, '-=.1')
                    .to(ligaCrunchyPart1,{
                        duration: .6,
                        translateX: 0,
                        translateY: 0,
                        ease: 'expo.out'
                    }, '-=.2')
                    .to(ligaCrunchyPart2,{
                        duration: .6,
                        translateX: '10%',
                        translateY: '-7%',
                        rotate: '22deg',
                        ease: 'expo.out'
                    }, '-=.5')
                    .to(ligaDecor,{
                        duration: .4,
                        translateX: 0,
                        translateY: 0,
                        scale: 0.89,
                        opacity: 1,
                        ease: 'circ.out'
                    }, '-=.5')

            }

            // LIGA_Crunchy_Muesli-Chocolade (13)
            function liga_crunchy_muesli_chocolade() {
                let section = $('.visual-liga-cmc');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.7),
                    ligaCrunchy = $('.visual-liga-cmc__cookie'),
                    ligaCrunchyPart1 = $('.visual-liga-cmc__cookie-part1'),
                    ligaCrunchyPart2 = $('.visual-liga-cmc__cookie-part2'),
                    ligaBG     = $('.visual-liga-cmc__bg'),
                    ligaDecor = $('.visual-liga-cmc__decor');


                // Set
                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaCrunchy, {
                    opacity: 1,
                    scale: 0,
                    y: 50
                });
                TweenMax.set(ligaCrunchyPart1, {
                    opacity: 0,
                    translateX: '10%',
                    translateY: '-4%'
                });
                TweenMax.set(ligaCrunchyPart2, {
                    opacity: 0,
                    rotate: '-16deg',
                    transformOrigin: '90% 50%'
                });
                TweenMax.set(ligaDecor, {
                    opacity: 0,
                    translateX: '14%',
                    translateY: '18%',
                    scale: 0.5
                });


                tl
                    .to(ligaBG, {
                        duration: .5,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaCrunchy, {
                        duration: .6,
                        scale: 1.2,
                        y: 0,
                        ease: 'back(1.7)'
                    }, '-=.2')
                    .to(ligaCrunchy, {
                        scale: 1,
                        duration: 1,
                        yPercent: -39,
                        rotate: 32,
                        ease: 'power4.out'
                    }, '-=.1')
                    .set(ligaCrunchy, {
                        opacity: 0,
                        // ease: 'expo.out'
                    }, '-=.4')
                    .to(ligaCrunchyPart1,{
                        duration: .6,
                        translateX: 0,
                        translateY: 0,
                        opacity: 1,
                        ease: 'expo.out'
                    }, '-=.37')
                    .to(ligaCrunchyPart2,{
                        duration: .6,
                        opacity: 1,
                        // translateX: '10%',
                        // translateY: '-7%',
                        rotate: -3,
                        ease: 'expo.out'
                    }, '<')
                    .to(ligaDecor,{
                        duration: .5,
                        translateX: 0,
                        translateY: 0,
                        scale: 0.89,
                        opacity: 1,
                        ease: 'circ.out'
                    }, '-=.5')

            }

            // LIGA_Crunchy_Muesli-rozijnen (14)
            function liga_crunchy_muesli_rozijnen() {
                let section = $('.visual-liga-cmr');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.7),
                    ligaCrunchy = $('.visual-liga-cmr__cookie'),
                    ligaCrunchyPart1 = $('.visual-liga-cmr__cookie-part1'),
                    ligaCrunchyPart2 = $('.visual-liga-cmr__cookie-part2'),
                    ligaBG     = $('.visual-liga-cmr__bg'),
                    ligaDecor = $('.visual-liga-cmr__decor');


                // Set
                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaCrunchy, {
                    opacity: 1,
                    scale: 0,
                    y: 50
                });
                TweenMax.set(ligaCrunchyPart1, {
                    opacity: 0,
                    translateX: '10%',
                    translateY: '-4%'
                });
                TweenMax.set(ligaCrunchyPart2, {
                    opacity: 0,
                    rotate: '-12deg',
                    transformOrigin: '100% 100%'
                });
                TweenMax.set(ligaDecor, {
                    opacity: 0,
                    translateX: '14%',
                    translateY: '18%',
                    scale: 0.5
                });


                tl
                    .to(ligaBG, {
                        duration: .45,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaCrunchy, {
                        keyframes: [
                            {
                                duration: .6,
                                scale: 1,
                                y: 0,
                                ease: 'back(1.7)'
                            },
                            {
                                duration: .75,
                                yPercent: -39,
                                rotate: 32,
                                ease: 'power4.out'
                            },
                            {
                                duration: 0,
                                opacity: 0,
                                delay: -0.2
                            }
                        ]
                    }, '-=.2')

                    .set([ligaCrunchyPart1, ligaCrunchyPart2], {
                        opacity: 1,

                        // ease: Sine.easeOut
                    },'>-.2')
                    .to(ligaCrunchyPart1,{
                        duration: .6,
                        translateX: 0,
                        translateY: 0,
                        ease: 'power4.out'
                    }, '<-.2')
                    .to(ligaCrunchyPart2,{
                        duration: .6,
                        translateX: '-10%',
                        translateY: '6%',
                        rotate: '28deg',
                        ease: 'power4.out'
                    }, '<')
                    .to(ligaDecor,{
                        duration: .4,
                        translateX: 0,
                        translateY: 0,
                        scale: 0.89,
                        opacity: 1,
                        ease: 'circ.out'
                    }, '<.2')

            }

            // LIGA_Evergreen_Appel (15)
            function liga_evergreen_appel() {
                let section = $('.visual-liga-ea');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaEvergreen = $('.visual-liga-ea__cookie'),
                    ligaEvergreenPart1 = $('.visual-liga-ea__cookie-part1'),
                    ligaEvergreenPart2 = $('.visual-liga-ea__cookie-part2'),
                    ligaEvergreenPart3 = $('.visual-liga-ea__cookie-part3'),
                    ligaBG     = $('.visual-liga-ea__bg'),
                    ligaDecor = $('.visual-liga-ea__decor');


                // Set
                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaEvergreen, {
                    opacity: 1,
                    scale: 0,
                    y: 50
                });
                TweenMax.set(ligaEvergreenPart1, {
                    opacity: 0,
                    translateX: '-2%',
                    translateY: '-4%'
                });
                TweenMax.set(ligaEvergreenPart2, {
                    opacity: 0,
                    translateX: '3%',
                    translateY: '19%',
                    transformOrigin: '80% 80%',
                    rotate: -20
                });
                TweenMax.set(ligaEvergreenPart3, {
                    opacity: 0,
                    scale: 0,
                    y: 50
                });
                TweenMax.set(ligaDecor, {
                    opacity: 0,
                    translateX: '20%',
                    translateY: '20%',
                    scale: 0.5
                });


                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaEvergreen, {
                        duration: .6,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, '-=.2')
                    .set(ligaEvergreen, {
                        opacity: 0,
                        // ease: 'expo.out'
                    }, '-=.1')
                    .set([ligaEvergreenPart1, ligaEvergreenPart2], {
                        opacity: 1,
                        // ease: Sine.easeOut
                    }, '-=.1')
                    .to(ligaEvergreenPart1,{
                        duration: .6,
                        translateX: 0,
                        translateY: 0,
                        ease: 'expo.out'
                    }, '-=.2')
                    .to(ligaEvergreenPart2,{
                        duration: .6,
                        translateX: '0',
                        translateY: '0',
                        rotate: '0',
                        ease: 'expo.out'
                    }, '-=.5')
                    .to(ligaEvergreenPart3,{
                        duration: .6,
                        scale: 1,
                        y: 0,
                        opacity: 1,
                        ease: 'expo.out'
                    }, '<')
                    .to(ligaDecor,{
                        duration: .4,
                        translateX: 0,
                        translateY: 0,
                        scale: 1,
                        opacity: 1,
                        ease: 'circ.out'
                    }, '<.1')

            }

            // LIGA_Evergreen_bosvruchten (16)
            function liga_evergreen_bosvruchten() {
                let section = $('.visual-liga-eb');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .3}).timeScale(.5),
                    ligaEvergreen = $('.visual-liga-eb__cookie'),
                    ligaEvergreenPart1 = $('.visual-liga-eb__cookie-part1'),
                    ligaEvergreenPart2 = $('.visual-liga-eb__cookie-part2'),
                    ligaEvergreenPart3 = $('.visual-liga-eb__cookie-part3'),
                    ligaBG     = $('.visual-liga-eb__bg'),
                    ligaDecor = $('.visual-liga-eb__decor');


                // Set
                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaEvergreen, {
                    opacity: 1,
                    scale: 0,
                    y: 0
                });
                TweenMax.set(ligaEvergreenPart1, {
                    opacity: 0,
                    translateX: '-3%',
                    translateY: '-2%',
                    rotate: 5
                });
                TweenMax.set(ligaEvergreenPart2, {
                    opacity: 0,
                    rotate: 22
                });
                TweenMax.set(ligaEvergreenPart3, {
                    opacity: 0,
                    transformOrigin: '80% 20%',
                    rotate: 18
                });
                TweenMax.set(ligaDecor, {
                    opacity: 0,
                    translateX: '-20%',
                    translateY: '21%',
                    scale: 0.7
                });


                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaEvergreen, {
                        duration: .6,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.7)'
                    }, '>.1')
                    .to(ligaEvergreen, {
                        duration: .6,
                        translateY: '15%',
                        ease: 'back(1.7)'
                    }, '-=.5')
                    .set(ligaEvergreen, {
                        opacity: 0,
                        // ease: 'expo.out'
                    }, '-=.2')
                    .set([ligaEvergreenPart1, ligaEvergreenPart2], {
                        opacity: 1,
                        // ease: Sine.easeOut
                    }, '-=.2')
                    .to(ligaEvergreenPart1,{
                        duration: .6,
                        translateX: 0,
                        translateY: '15%',
                        rotate: 0,
                        ease: 'expo.out'
                    }, '-=.2')
                    .to(ligaEvergreenPart2,{
                        duration: .6,
                        rotate: 0,
                        ease: 'expo.out'
                    }, '<')
                    .to(ligaEvergreenPart3,{
                        duration: .6,
                        rotate: 0,
                        translateX: '10%',
                        translateY: '4%',
                        opacity: 1,
                        ease: 'expo.out'
                    }, '<')
                    .to(ligaDecor,{
                        duration: .6,
                        translateX: 0,
                        translateY: 0,
                        scale: 1,
                        opacity: 1,
                        ease: 'circ.out'
                    }, '<')

            }

            // LiGA_Evergreen_Krenten (17)
            function liga_evergreen_krenten() {
                let section = $('.visual-liga-ek');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaEvergreen = $('.visual-liga-ek__cookie'),
                    ligaEvergreenPart1 = $('.visual-liga-ek__cookie-part1'),
                    ligaEvergreenPart2 = $('.visual-liga-ek__cookie-part2'),
                    ligaEvergreenPart3 = $('.visual-liga-ek__cookie-part3'),
                    ligaEvergreenPart3_2 = $('.visual-liga-ek__cookie-part3-2'),
                    ligaBG     = $('.visual-liga-ek__bg');


                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaEvergreen, {
                    opacity: 0,
                    scale: 0.5,
                    y: -30
                });
                TweenMax.set(ligaEvergreenPart1, {
                    opacity: 0,
                });
                TweenMax.set(ligaEvergreenPart2, {
                    opacity: 0,
                });
                TweenMax.set(ligaEvergreenPart3, {
                    opacity: 0,
                    transformOrigin: 'center',
                    scale: .4
                });
                TweenMax.set(ligaEvergreenPart3_2, {
                    opacity: 0,
                    scale: 0.7
                });

                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaEvergreen,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.4)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: .4,
                                    yPercent: 10,
                                    ease: 'back(1.7)',
                                    delay: '-0.4'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaEvergreenPart1, ligaEvergreenPart2], {
                        opacity: 1,
                    })

                    .to(ligaEvergreenPart1,{
                        transformOrigin: '50% 100%',
                        duration: .6,
                        rotate: -2,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaEvergreenPart2,{
                        duration: .6,
                        rotate: 26,
                        xPercent: 18,
                        yPercent: -22,
                        ease: 'expo.out'
                    }, '<')

                    .to(ligaEvergreenPart3_2,{
                        keyframes: [

                            {
                                duration: .6,
                                scale: 1,
                                opacity: 1,
                                ease: 'expo.out',

                            }

                        ]
                    }, '<')

            }

            // LiGA_Haverkick_Appel (18)
            function liga_haverkick_appel() {
                let section = $('.visual-liga-ha');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaHaverkick = $('.visual-liga-ha__cookie'),
                    ligaHaverkickPart1 = $('.visual-liga-ha__cookie-part1'),
                    ligaHaverkickPart2 = $('.visual-liga-ha__cookie-part2'),
                    ligaHaverkickDecor = $('.visual-liga-ha__decor'),
                    ligaBG     = $('.visual-liga-ha__bg');


                // Set
                // TweenMax.set(section, {
                //     perspective: 100
                // });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaHaverkick, {
                    opacity: 0,
                    scale: 0.2,
                    y: -10
                });
                TweenMax.set(ligaHaverkickPart1, {
                    opacity: 0,
                    xPercent: 2,
                    yPercent: -2
                });
                TweenMax.set(ligaHaverkickPart2, {
                    opacity: 0,
                    rotate: -14,
                    xPercent: -4,
                    yPercent: 2
                });
                TweenMax.set(ligaHaverkickDecor, {
                    opacity: 0,
                    scale: 0.5
                });

                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaHaverkick,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaHaverkickPart1, ligaHaverkickPart2], {
                        opacity: 1,
                    })

                    .to(ligaHaverkickPart1,{
                        duration: .6,
                        rotate: 0,
                        xPercent: -6,
                        yPercent: 3,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaHaverkickPart2,{
                        duration: .6,
                        rotate: -8,
                        xPercent: 5,
                        yPercent: -2,
                        ease: 'expo.out'
                    }, '<')

                    .to(ligaHaverkickDecor,{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        ease: 'expo.out',
                    }, '<')

            }

            // LiGA_Haverkick_Bosvruchten (19)
            function liga_haverkick_bosvruchten() {
                let section = $('.visual-liga-hb');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.7),
                    ligaHaverkick = $('.visual-liga-hb__cookie'),
                    ligaHaverkickPart1 = $('.visual-liga-hb__cookie-part1'),
                    ligaHaverkickPart2 = $('.visual-liga-hb__cookie-part2'),
                    ligaHaverkickDecor = $('.visual-liga-hb__decor1, .visual-liga-hb__decor2'),
                    ligaBG     = $('.visual-liga-hb__bg');


                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaHaverkick, {
                    opacity: 0,
                    scale: 0.2,
                    y: -10
                });
                TweenMax.set(ligaHaverkickPart1, {
                    opacity: 0,

                });
                TweenMax.set(ligaHaverkickPart2, {
                    opacity: 0,

                });
                TweenMax.set(ligaHaverkickDecor, {
                    opacity: 0,
                    scale: 0.2,
                    y: -20,
                    transformOrigin: '70% 70%'
                });

                tl
                    .to(ligaBG, {
                        duration: .5,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaHaverkick,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaHaverkickPart1, ligaHaverkickPart2], {
                        opacity: 1,
                    })

                    .to(ligaHaverkickPart1,{
                        duration: .6,
                        rotateX: -3,
                        rotateY: -2,
                        xPercent: -6,
                        yPercent: 5,
                        scale: 1.1,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaHaverkickPart2,{
                        duration: .6,
                        rotateX: 3,
                        rotateY: 2,
                        xPercent: 10,
                        yPercent: -7,
                        scale: 1.1,
                        ease: 'expo.out'
                    }, '<')

                    .to(ligaHaverkickDecor,{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        y: 0,
                        ease: 'back(1)',
                        stagger: .15
                    }, '<.2')

            }

            // LIGA_Haverrepen_honing (20)
            function liga_haverrepen_honing() {
                let section = $('.visual-liga-hh');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(1),
                    ligaHaverrepen = $('.visual-liga-hh__cookie'),
                    ligaHaverrepenState2 = $('.visual-liga-hh__cookie-state2'),
                    ligaHaverrepenDecor = $('.visual-liga-hh__decor'),
                    ligaHaverrepenDecor2 = $('.visual-liga-hh__honey1,.visual-liga-hh__honey2,.visual-liga-hh__honey3,.visual-liga-hh__honey4'),
                    ligaBG     = $('.visual-liga-hh__bg');


                // Set
                TweenMax.set(section, {
                    perspective: 200
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaHaverrepen, {
                    opacity: 0,
                    scale: 0.2,
                    y: 10
                });
                TweenMax.set(ligaHaverrepenState2, {
                    opacity: 0,
                    rotate: 10
                });

                TweenMax.set(ligaHaverrepenDecor, {
                    opacity: 0,
                    scale: 0.2,
                    xPercent: -20,
                    yPercent: -20,
                    transformOrigin: '70% 70%'
                });
                TweenMax.set(ligaHaverrepenDecor2, {
                    opacity: 0,
                    z: 50,
                });

                tl
                    .to(ligaBG, {
                        duration: .7,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaHaverrepen,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .8,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0.2,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .to(ligaHaverrepenState2,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1,
                                },
                                {
                                    duration: .6,
                                    rotate: 0,
                                },
                            ]

                        }
                    )

                    .to(ligaHaverrepenDecor,{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        xPercent: 0,
                        yPercent: 0,
                        ease: 'back(1)'
                    }, '<.2')

                    .to(ligaHaverrepenDecor2,{
                        duration: .4,
                        scale: 1,
                        z: 0,
                        opacity: 1,
                        stagger: .1
                    }, '<.2')

            }

            // LIGA_Haverrepen_kokos (21)
            function liga_haverrepen_kokos() {
                let section = $('.visual-liga-hk');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(1),
                    ligaHaverrepen = $('.visual-liga-hk__cookie'),
                    ligaHaverrepenState2 = $('.visual-liga-hk__cookie-state2'),
                    ligaHaverrepenDecor = $('.visual-liga-hk__decor1,.visual-liga-hk__decor2,.visual-liga-hk__decor3'),
                    ligaBG     = $('.visual-liga-hk__bg');


                // Set
                TweenMax.set(section, {
                    perspective: 200
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaHaverrepen, {
                    opacity: 0,
                    scale: 0.2,
                    y: 10
                });
                TweenMax.set(ligaHaverrepenState2, {
                    opacity: 0,
                    rotate: 10
                });

                TweenMax.set(ligaHaverrepenDecor, {
                    opacity: 0,
                    scale: 0
                });

                tl
                    .to(ligaBG, {
                        duration: .7,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaHaverrepen,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .8,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: .4,
                                    rotate: -10,
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0.2,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .to(ligaHaverrepenState2,
                        {
                            keyframes: [
                                {
                                    duration: 0.2,
                                    opacity: 1,
                                },
                                {
                                    duration: .6,
                                    rotate: 0,
                                },
                            ]

                        },'>-.2'
                    )

                    .to(ligaHaverrepenDecor,{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        ease: 'out',
                        stagger: .06
                    }, '<-.1')


            }

            // LIGA_KinderBiscuits (22)
            function liga_kinderbiscuits() {
                let section = $('.visual-liga-kb');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .5}).timeScale(.6),
                    ligaKinderbiscuit = $('.visual-liga-kb__cookie'),
                    ligaKinderbiscuitPart1 = $('.visual-liga-kb__cookie-part1'),
                    ligaKinderbiscuitPart2 = $('.visual-liga-kb__cookie-part2'),
                    ligaBG     = $('.visual-liga-kb__bg');


                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaKinderbiscuit, {
                    opacity: 0,
                    scale: 1,
                    translateZ: 12,
                    yPercent: -8
                });
                TweenMax.set([ligaKinderbiscuitPart1,ligaKinderbiscuitPart2], {
                    opacity: 0,
                    scale: 0
                });
                TweenMax.set(ligaKinderbiscuitPart1,{
                    transformOrigin: '50% 90%',
                    xPercent: -32,
                    yPercent: -2,
                    scale: .4
                });
                TweenMax.set(ligaKinderbiscuitPart2,{
                    transformOrigin: '58% 91%',
                    xPercent: 22,
                    rotate: -2,
                    scale: .4
                });


                tl
                    .to(ligaBG, {
                        duration: .45,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)',
                    })

                    .to([ligaKinderbiscuitPart1,ligaKinderbiscuitPart2],
                        {
                            duration: .6,
                            opacity: 1,
                            scale: 1,
                            ease: 'back(1.2)',
                        },
                    )
                    .to(ligaKinderbiscuitPart1,
                        {
                            keyframes: [

                                {
                                    duration: .2,
                                    rotate: 3,
                                    yoyo: true,
                                    repeat: 5
                                },
                                {
                                    duration: 1,
                                    xPercent: 20,
                                    delay: -1,
                                },
                                {
                                    duration: .4,
                                    yPercent: -40,
                                    delay: -.1,
                                    yoyo: true,
                                    repeat: 1,
                                    ease: 'power2.out',
                                },
                                {
                                    duration: .4,
                                    yPercent: -40,
                                    delay: .9,
                                    yoyo: true,
                                    repeat: 1,
                                    ease: 'power2.out',
                                },
                                {
                                    duration: .4,
                                    xPercent: 37,
                                    yPercent: -15,
                                    rotate: 5,
                                    delay: 0,
                                    ease: 'power2.out',
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                },

                            ]

                        }, '>-.3'
                    )
                    .to(ligaKinderbiscuitPart2,
                        {
                            keyframes: [
                                {
                                    duration: .2,
                                    rotate: -5,
                                    yoyo: true,
                                    repeat: 5
                                },
                                {
                                    duration: 1,
                                    xPercent: -4,
                                    delay: -1,
                                },
                                {
                                    duration: .4,
                                    yPercent: -40,
                                    delay: .8,
                                    yoyo: true,
                                    repeat: 1,
                                    ease: 'power2.out',
                                },
                                {
                                    duration: .4,
                                    yPercent: -40,
                                    delay: 0,
                                    yoyo: true,
                                    repeat: 1,
                                    ease: 'power2.out',
                                },
                                {
                                    duration: .4,
                                    xPercent: -10,
                                    yPercent: -12,
                                    rotate: -8,
                                    delay: 0,
                                    ease: 'power2.out',
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                },

                            ]

                        }, '<'
                    )
                    .to(ligaKinderbiscuit,
                        {
                            keyframes: [
                                {
                                    duration: 0.05,
                                    opacity: 1
                                },
                                {

                                    duration: .6,
                                    scale: 1,
                                    translateZ: 0,
                                    yPercent: 0,
                                    ease: 'power2.in',
                                }
                            ]
                        }, '>-.15'
                    )


            }


            // LIGA_Milkbreak_Bosvruchten-Framboos (23)
            function liga_milkbreak_bosvruchten_framboos() {
                let section = $('.visual-liga-mbf');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaMilkbreakCookie1 = $('.visual-liga-mbf__cookie1'),
                    ligaMilkbreakCookie2 = $('.visual-liga-mbf__cookie2'),
                    ligaMilkbreakCookie3 = $('.visual-liga-mbf__cookie3'),
                    ligaMilkbreakCookie4 = $('.visual-liga-mbf__cookie4'),
                    ligaMilkbreakDecor1 = $('.visual-liga-mbf__decor1'),
                    ligaMilkbreakDecor2 = $('.visual-liga-mbf__decor2'),
                    ligaMilkbreakDecor3 = $('.visual-liga-mbf__decor3'),
                    ligaMilkbreakDecor4 = $('.visual-liga-mbf__decor4'),
                    ligaBG     = $('.visual-liga-mbf__bg'),

                    cookies = [ligaMilkbreakCookie1,ligaMilkbreakCookie2,ligaMilkbreakCookie3,ligaMilkbreakCookie4],
                    decors = [ligaMilkbreakDecor1,ligaMilkbreakDecor2,ligaMilkbreakDecor3,ligaMilkbreakDecor4];



                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    opacity: 0,
                    translateZ: 40
                });
                TweenMax.set(ligaMilkbreakCookie1,{
                    transformOrigin: '77% 82%',
                    rotate: -27
                });
                TweenMax.set(decors, {
                    opacity: 0,
                    scale: 0
                });
                TweenMax.set([ligaMilkbreakDecor1,ligaMilkbreakDecor2],{
                    transformOrigin: '0 50%'
                });
                TweenMax.set(ligaMilkbreakDecor3,{
                    transformOrigin: '0% 100%'
                });
                TweenMax.set(ligaMilkbreakDecor4,{
                    transformOrigin: 'center'
                });


                tl
                    .to(ligaBG, {
                        duration: .35,
                        scale: 1,
                        y: 0,
                        delay: .3,
                        ease: 'back(1.2)',
                    })
                    .to(cookies,
                        {
                            duration: .7,
                            translateZ: 0,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .15,
                        },
                    )
                    .to(ligaMilkbreakCookie1,
                        {
                            duration: .25,
                            rotate: 0,
                            ease: 'back(1.7)',
                        }, '>-.5'
                    )
                    .to(decors,
                        {
                            duration: .4,
                            scale: 1,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .1
                        }, '>-.2'
                    )

            }

            // LIGA_Milkbreak_Melk-Aardbei (24)
            function liga_milkbreak_melk_aardbei(){
                let section = $('.visual-liga-mma');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaMilkbreakCookie1 = $('.visual-liga-mma__cookie1'),
                    ligaMilkbreakCookie2 = $('.visual-liga-mma__cookie2'),
                    ligaMilkbreakCookie3 = $('.visual-liga-mma__cookie3'),
                    ligaMilkbreakCookie4 = $('.visual-liga-mma__cookie4'),
                    ligaMilkbreakDecor1 = $('.visual-liga-mma__decor1'),
                    ligaMilkbreakDecor2 = $('.visual-liga-mma__decor2'),
                    ligaBG     = $('.visual-liga-mma__bg'),

                    cookies = [ligaMilkbreakCookie1,ligaMilkbreakCookie2,ligaMilkbreakCookie3,ligaMilkbreakCookie4];




                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    opacity: 0,
                    translateZ: 40
                });
                TweenMax.set(ligaMilkbreakCookie1,{
                    transformOrigin: '16% 86%',
                    rotate: 22
                });
                TweenMax.set(ligaMilkbreakDecor1, {
                    opacity: 0,
                    scale: 0,
                    transformOrigin: 'center'
                });
                TweenMax.set(ligaMilkbreakDecor2, {
                    opacity: 0,
                    scale: 0,
                    transformOrigin: '100% 10%'
                });



                tl
                    .to(ligaBG, {
                        duration: .35,
                        scale: 1,
                        y: 0,
                        delay: .3,
                        ease: 'back(1.2)',
                    })
                    .to(cookies,
                        {
                            duration: .7,
                            translateZ: 0,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .15,
                        },
                    )
                    .to(ligaMilkbreakCookie1,
                        {
                            duration: .25,
                            rotate: 0,
                            ease: 'back(1.7)',
                        }, '>-.5'
                    )
                    .to([ligaMilkbreakDecor1,ligaMilkbreakDecor2],
                        {
                            duration: .3,
                            scale: 1,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .15
                        }, '>-.2'
                    )

            }

            // LIGA_Milkbreak_Melk-Choco (25)
            function liga_milkbreak_melk_choco(){
                let section = $('.visual-liga-mmc');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaMilkbreakCookie1 = $('.visual-liga-mmc__cookie1'),
                    ligaMilkbreakCookie2 = $('.visual-liga-mmc__cookie2'),
                    ligaMilkbreakCookie3 = $('.visual-liga-mmc__cookie3'),
                    ligaMilkbreakCookie4 = $('.visual-liga-mmc__cookie4'),
                    ligaMilkbreakDecor1 = $('.visual-liga-mmc__decor1'),
                    ligaMilkbreakDecor2 = $('.visual-liga-mmc__decor2'),
                    ligaBG     = $('.visual-liga-mmc__bg'),

                    cookies = [ligaMilkbreakCookie1,ligaMilkbreakCookie2,ligaMilkbreakCookie3,ligaMilkbreakCookie4];

                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    opacity: 0,
                    translateZ: 40
                });
                TweenMax.set(ligaMilkbreakCookie1,{
                    transformOrigin: '17% 65%',
                    rotate: -32
                });
                TweenMax.set([ligaMilkbreakDecor1,ligaMilkbreakDecor2], {
                    opacity: 0,
                    scale: 0,
                    transformOrigin: 'center'
                });


                tl
                    .to(ligaBG, {
                        duration: .35,
                        scale: 1,
                        y: 0,
                        delay: .3,
                        ease: 'back(1.2)',
                    })
                    .to(cookies,
                        {
                            duration: .7,
                            translateZ: 0,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .15,
                        },
                    )
                    .to(ligaMilkbreakCookie1,
                        {
                            duration: .25,
                            rotate: 0,
                            ease: 'back(1.7)',
                        }, '>-.5'
                    )
                    .to([ligaMilkbreakDecor1,ligaMilkbreakDecor2],
                        {
                            duration: .3,
                            scale: 1,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .15
                        }, '>-.2'
                    )

            }

            // LIGA_Milkbreak_Melk (26)
            function liga_milkbreak_melk() {
                let section = $('.visual-liga-mm');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaMilkbreakCookie1 = $('.visual-liga-mm__cookie1'),
                    ligaMilkbreakCookie2 = $('.visual-liga-mm__cookie2'),
                    ligaMilkbreakCookie3 = $('.visual-liga-mm__cookie3'),
                    ligaMilkbreakCookie4 = $('.visual-liga-mm__cookie4'),
                    ligaMilkbreakDecor1 = $('.visual-liga-mm__decor1'),
                    ligaBG     = $('.visual-liga-mm__bg'),

                    cookies = [ligaMilkbreakCookie1,ligaMilkbreakCookie2,ligaMilkbreakCookie3,ligaMilkbreakCookie4];




                // Set
                TweenMax.set(section, {
                    perspective: 100
                });

                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(cookies, {
                    opacity: 0,
                    translateZ: 40
                });
                TweenMax.set(ligaMilkbreakCookie1,{
                    transformOrigin: '99% 45%',
                    rotate: 43
                });
                TweenMax.set(ligaMilkbreakDecor1, {
                    opacity: 0,
                    scale: 0,
                    transformOrigin: 'center'
                });



                tl
                    .to(ligaBG, {
                        duration: .35,
                        scale: 1,
                        y: 0,
                        delay: .3,
                        ease: 'back(1.2)',
                    })
                    .to(cookies,
                        {
                            duration: .7,
                            translateZ: 0,
                            opacity: 1,
                            ease: 'back(1.7)',
                            stagger: .15,
                        },
                    )
                    .to(ligaMilkbreakCookie1,
                        {
                            duration: .25,
                            rotate: 0,
                            ease: 'back(1.7)',
                        }, '>-.5'
                    )
                    .to(ligaMilkbreakDecor1,
                        {
                            duration: .3,
                            scale: 1,
                            opacity: 1,
                            ease: 'back(1.7)',
                        }, '>-.2'
                    )

            }

            // LIGA_Proteinereep_Cranberry (27)
            function liga_proteinereep_cranberry() {
                let section = $('.visual-liga-pc');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaProteinereep = $('.visual-liga-pc__cookie'),
                    ligaProteinereepPart1 = $('.visual-liga-pc__cookie-part1'),
                    ligaProteinereepPart2 = $('.visual-liga-pc__cookie-part2'),
                    ligaProteinereepDecor1 = $('.visual-liga-pc__decor1'),
                    ligaProteinereepDecor2 = $('.visual-liga-pc__decor2'),
                    ligaProteinereepDecor3 = $('.visual-liga-pc__decor3'),
                    ligaBG     = $('.visual-liga-pc__bg');


                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaProteinereep, {
                    opacity: 0,
                    scale: 0.2,
                    y: -10
                });
                TweenMax.set(ligaProteinereepPart1, {
                    opacity: 0,
                    xPercent: 2,
                    yPercent: -2
                });
                TweenMax.set(ligaProteinereepPart2, {
                    opacity: 0,
                    rotate: -14,
                    xPercent: -4,
                    yPercent: 2
                });
                TweenMax.set([ligaProteinereepDecor1,ligaProteinereepDecor2,ligaProteinereepDecor3], {
                    opacity: 0,
                    scale: 0
                });

                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaProteinereep,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaProteinereepPart1, ligaProteinereepPart2], {
                        opacity: 1,
                    })

                    .to(ligaProteinereepPart1,{
                        duration: .6,
                        rotate: 0,
                        xPercent: -6,
                        yPercent: 3,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaProteinereepPart2,{
                        duration: .6,
                        rotate: -8,
                        xPercent: 5,
                        yPercent: -2,
                        ease: 'expo.out'
                    }, '<')

                    .to([ligaProteinereepDecor1,ligaProteinereepDecor2,ligaProteinereepDecor3],{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        ease: 'expo.out',
                    }, '<')

            }

            // LIGA_Proteinereep_pinda (28)
            function liga_proteinereep_pinda() {
                let section = $('.visual-liga-pp');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaProteinereep = $('.visual-liga-pp__cookie'),
                    ligaProteinereepPart1 = $('.visual-liga-pp__cookie-part1'),
                    ligaProteinereepPart2 = $('.visual-liga-pp__cookie-part2'),
                    ligaProteinereepDecor1 = $('.visual-liga-pp__decor1'),
                    ligaProteinereepDecor2 = $('.visual-liga-pp__decor2'),
                    ligaBG     = $('.visual-liga-pp__bg');


                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaProteinereep, {
                    opacity: 0,
                    scale: 0.2,
                    y: -10
                });
                TweenMax.set(ligaProteinereepPart1, {
                    opacity: 0,
                    xPercent: 2,
                    yPercent: -2
                });
                TweenMax.set(ligaProteinereepPart2, {
                    opacity: 0,
                    rotate: -14,
                    xPercent: -4,
                    yPercent: 2
                });
                TweenMax.set([ligaProteinereepDecor1,ligaProteinereepDecor2], {
                    opacity: 0,
                    scale: 0
                });

                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaProteinereep,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaProteinereepPart1, ligaProteinereepPart2], {
                        opacity: 1,
                    })

                    .to(ligaProteinereepPart1,{
                        duration: .6,
                        rotate: 0,
                        xPercent: -6,
                        yPercent: 3,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaProteinereepPart2,{
                        duration: .6,
                        rotate: -8,
                        xPercent: 5,
                        yPercent: -2,
                        ease: 'expo.out'
                    }, '<')

                    .to([ligaProteinereepDecor1,ligaProteinereepDecor2],{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        ease: 'expo.out',
                    }, '<')

            }

            // VITALU_Meergranen (29)
            function vitalu_meergranen() {
                let section = $('.visual-vitalu-m');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.74),
                    vitaluCookie = $('.visual-vitalu-m__cookie'),
                    vitaluCookiePart1 = $('.visual-vitalu-m__cookie-part1'),
                    vitaluCookiePart2 = $('.visual-vitalu-m__cookie-part2'),
                    vitaluBG      = $('.visual-vitalu-m__bg'),
                    vitaluDecor1 = $('.visual-vitalu-m__decor1'),
                    vitaluDecor2 = $('.visual-vitalu-m__decor2'),
                    vitaluDecor3 = $('.visual-vitalu-m__decor3');


                // Set
                TweenMax.set(vitaluBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(vitaluCookie, {
                    opacity: 0,
                    rotate: -27,
                    scale: 0.8,
                    xPercent: -13,
                    yPercent: 17,
                    transformOrigin: 'center'
                });
                TweenMax.set(vitaluCookiePart1, {
                    opacity: 0,
                    xPercent: 10,
                    yPercent: -8,
                });
                TweenMax.set(vitaluCookiePart2, {
                    opacity: 0,
                    rotate: 2,
                    xPercent: -5,
                    yPercent: 3,
                });

                TweenMax.set(vitaluDecor1, {
                    y: 60,
                    opacity: 0,
                    scale: .6,
                    transformOrigin: '0% 100%'
                });
                TweenMax.set([vitaluDecor2,vitaluDecor3], {
                    opacity: 0,
                    scale: 0,
                });



                tl
                    //first step
                    .to(vitaluBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(vitaluCookie, {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                                delay: -0.3
                            },
                            {
                                duration: .7,
                                scale: 1,
                                rotate: 16,
                                yPercent: -11,
                                xPercent: 0,
                                ease: 'circ.out'
                            },
                            {
                                duration: 0,
                                opacity: 0,
                            }
                        ]

                    }, '>-0.2')

                    .to(vitaluCookiePart1, {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                            },
                            {
                                duration: .5,
                                xPercent: 0,
                                yPercent: 0,
                                ease: 'circ.out'
                            },
                        ]
                    })
                    .to(vitaluCookiePart2, {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                            },
                            {
                                duration: .5,
                                rotate: 0,
                                xPercent: 0,
                                yPercent: 0,
                                ease: 'circ.out'
                            },
                        ]
                    }, '<')

                    .to([vitaluDecor2,vitaluDecor3], {
                        duration: .45,
                        opacity: 1,
                        scale: 1,
                        stagger: .15,
                        ease: 'back(1.7)',
                    }, '-=.6')
                    .to(vitaluDecor1, {
                        duration: .45,
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        ease: 'back(1.7)',

                    }, '<.2')

            }

            // VITALU_Volkoren_Tarwemeel (30)
            function vitalu_volkoren_tarwemeel() {
                let section = $('.visual-vitalu-vt');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.65),
                    vitaluCookie = $('.visual-vitalu-vt__cookie'),
                    vitaluCookiePart1 = $('.visual-vitalu-vt__cookie-part1'),
                    vitaluCookiePart2 = $('.visual-vitalu-vt__cookie-part2'),
                    vitaluBG      = $('.visual-vitalu-vt__bg'),
                    vitaluDecor1 = $('.visual-vitalu-vt__decor1'),
                    vitaluDecor2 = $('.visual-vitalu-vt__decor2'),
                    vitaluDecor3 = $('.visual-vitalu-vt__decor3');


                // Set
                TweenMax.set(vitaluBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(vitaluCookie, {
                    opacity: 0,
                    rotate: -28,
                    scale: 0.8,
                    xPercent: -8,
                    yPercent: 13,
                    transformOrigin: '56%'
                });
                TweenMax.set(vitaluCookiePart1, {
                    opacity: 0,
                    rotate: 12,
                    xPercent: 10,
                    yPercent: 10,
                });
                TweenMax.set(vitaluCookiePart2, {
                    opacity: 0,
                    rotate: -11,
                    xPercent: -6,
                    yPercent: 2,
                });
                TweenMax.set(vitaluDecor1, {
                    y: 60,
                    opacity: 0,
                    scale: .6,
                    transformOrigin: '0% 100%'
                });
                TweenMax.set([vitaluDecor2,vitaluDecor3], {
                    opacity: 0,
                    scale: 0,
                });



                tl
                    //first step
                    .to(vitaluBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(vitaluCookie, {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                                delay: .2
                            },
                            {
                                duration: .7,
                                scale: 1,
                                rotate: -7,
                                xPercent: 0,
                                yPercent: -6,
                                ease: 'circ.out'
                            },
                            {
                                duration: 0,
                                opacity: 0,
                            }
                        ]

                    },'.4')

                    .to([vitaluCookiePart1,vitaluCookiePart2], {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                            },
                            {
                                duration: .5,
                                rotate: 0,
                                xPercent: 0,
                                yPercent: 0,
                                ease: 'circ.out'
                            },
                        ]
                    })


                    .to([vitaluDecor2,vitaluDecor3], {
                        duration: .45,
                        opacity: 1,
                        scale: 1,
                        stagger: .15,
                        ease: 'back(1.7)',
                    }, '-=.6')
                    .to(vitaluDecor1, {
                        duration: .45,
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        ease: 'back(1.7)',

                    }, '<.2')

            }
            // VITALU_Volkoren (31)
            function vitalu_volkoren() {
                let section = $('.visual-vitalu-v');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    vitaluCookie = $('.visual-vitalu-v__cookie'),
                    vitaluCookiePart1 = $('.visual-vitalu-v__cookie-part1'),
                    vitaluCookiePart2 = $('.visual-vitalu-v__cookie-part2'),
                    vitaluBG      = $('.visual-vitalu-v__bg'),
                    vitaluDecor1 = $('.visual-vitalu-v__decor1'),
                    vitaluDecor2 = $('.visual-vitalu-v__decor2'),
                    vitaluDecor3 = $('.visual-vitalu-v__decor3');


                // Set
                TweenMax.set(vitaluBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(vitaluCookie, {
                    opacity: 0,
                    rotate: 0,
                    scale: 0.8,
                    xPercent: 12,
                    yPercent: 13,
                    transformOrigin: '50%'
                });
                TweenMax.set(vitaluCookiePart1, {
                    opacity: 0,
                    xPercent: 10,
                    yPercent: -9,
                });
                TweenMax.set(vitaluCookiePart2, {
                    opacity: 0,
                    xPercent: 4,
                    yPercent: 1,
                });
                TweenMax.set(vitaluDecor1, {
                    y: 60,
                    opacity: 0,
                    scale: .6,
                    transformOrigin: '0% 100%'
                });
                TweenMax.set([vitaluDecor2,vitaluDecor3], {
                    opacity: 0,
                    scale: 0,
                });



                tl
                    //first step
                    .to(vitaluBG, .6, {
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(vitaluCookie, {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                                delay: .2
                            },
                            {
                                duration: .7,
                                scale: .9,
                                rotate: 43,
                                xPercent: 3,
                                yPercent: -7,
                                ease: 'circ.out'
                            },
                            {
                                duration: 0,
                                opacity: 0,
                            }
                        ]

                    },'.4')

                    .to([vitaluCookiePart1,vitaluCookiePart2], {
                        keyframes: [
                            {
                                duration: 0,
                                opacity: 1,
                            },
                            {
                                duration: .5,
                                xPercent: 0,
                                yPercent: 0,
                                ease: 'circ.out'
                            },
                        ]
                    })


                    .to([vitaluDecor2,vitaluDecor3], {
                        duration: .45,
                        opacity: 1,
                        scale: 1,
                        stagger: .15,
                        ease: 'back(1.7)',
                    }, '-=.6')
                    .to(vitaluDecor1, {
                        duration: .45,
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        ease: 'back(1.7)',

                    }, '<.2')

            }

            // LiGA_Noten_Granenrepen (32)
            function liga_noten_granenrepen() {
                let section = $('.visual-liga-ng');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaNotenGranenrepen = $('.visual-liga-ng__cookie'),
                    ligaNotenGranenrepenPart1 = $('.visual-liga-ng__cookie-part1'),
                    ligaNotenGranenrepenPart2 = $('.visual-liga-ng__cookie-part2'),
                    ligaNotenGranenrepenDecor1 = $('.visual-liga-ng__decor1'),
                    ligaNotenGranenrepenDecor2 = $('.visual-liga-ng__decor2'),
                    ligaBG     = $('.visual-liga-ng__bg');


                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaNotenGranenrepen, {
                    opacity: 0,
                    scale: 0.2,
                    y: -10
                });
                TweenMax.set(ligaNotenGranenrepenPart1, {
                    opacity: 0,
                    xPercent: 2,
                    yPercent: -2
                });
                TweenMax.set(ligaNotenGranenrepenPart2, {
                    opacity: 0,
                    rotate: -14,
                    xPercent: -4,
                    yPercent: 2
                });
                TweenMax.set([ligaNotenGranenrepenDecor1,ligaNotenGranenrepenDecor2], {
                    opacity: 0,
                    scale: 0
                });

                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaNotenGranenrepen,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaNotenGranenrepenPart1, ligaNotenGranenrepenPart2], {
                        opacity: 1,
                    })

                    .to(ligaNotenGranenrepenPart1,{
                        duration: .6,
                        rotate: 0,
                        xPercent: -6,
                        yPercent: 3,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaNotenGranenrepenPart2,{
                        duration: .6,
                        rotate: -8,
                        xPercent: 5,
                        yPercent: -2,
                        ease: 'expo.out'
                    }, '<')

                    .to([ligaNotenGranenrepenDecor1,ligaNotenGranenrepenDecor2],{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        ease: 'expo.out',
                    }, '<')

            }

            // LiGA_Notenrepen (33)
            function liga_notenrepen() {
                let section = $('.visual-liga-notenrepen');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(.6),
                    ligaNotenrepen = $('.visual-liga-notenrepen__cookie'),
                    ligaNotenrepenPart1 = $('.visual-liga-notenrepen__cookie-part1'),
                    ligaNotenrepenPart2 = $('.visual-liga-notenrepen__cookie-part2'),
                    ligaNotenrepenDecor1 = $('.visual-liga-notenrepen__decor1'),
                    ligaNotenrepenDecor2 = $('.visual-liga-notenrepen__decor2'),
                    ligaBG     = $('.visual-liga-notenrepen__bg');


                TweenMax.set(ligaBG, {
                    scale: 0,
                    y: 20
                });
                TweenMax.set(ligaNotenrepen, {
                    opacity: 0,
                    scale: 0.2,
                    y: -10
                });
                TweenMax.set(ligaNotenrepenPart1, {
                    opacity: 0,
                    xPercent: 2,
                    yPercent: -2
                });
                TweenMax.set(ligaNotenrepenPart2, {
                    opacity: 0,
                    rotate: -14,
                    xPercent: -4,
                    yPercent: 2
                });
                TweenMax.set([ligaNotenrepenDecor1,ligaNotenrepenDecor2], {
                    opacity: 0,
                    scale: 0
                });

                tl
                    .to(ligaBG, {
                        duration: .4,
                        scale: 1,
                        y: 0,
                        ease: 'back(1.2)'
                    })
                    .to(ligaNotenrepen,
                        {
                            keyframes: [
                                {
                                    duration: 0,
                                    opacity: 1
                                },
                                {
                                    duration: .6,
                                    scale: 1,
                                    y: 0,
                                    ease: 'back(1.7)',
                                    delay: '-=.2'
                                },
                                {
                                    duration: 0,
                                    opacity: 0,
                                }
                            ]
                        }
                    )
                    .set([ligaNotenrepenPart1, ligaNotenrepenPart2], {
                        opacity: 1,
                    })

                    .to(ligaNotenrepenPart1,{
                        duration: .6,
                        rotate: 0,
                        xPercent: -6,
                        yPercent: 3,
                        ease: 'expo.out'
                    }, '>')
                    .to(ligaNotenrepenPart2,{
                        duration: .6,
                        rotate: -8,
                        xPercent: 5,
                        yPercent: -2,
                        ease: 'expo.out'
                    }, '<')

                    .to([ligaNotenrepenDecor1,ligaNotenrepenDecor2],{
                        duration: .6,
                        scale: 1,
                        opacity: 1,
                        ease: 'expo.out',
                    }, '<')

            }


            belvita_breakfast_chocolade();
            belvita_breakfast_honing();
            belvita_breakfast_meergranen();
            belvita_breakfast_melk_en_granen();

            belvita_sandwich_yoghurt_aardbei();
            belvita_bakes_chocolade_5granen_v1();
            belvita_bakes_chocolade_5granen_v2();

            cracotte_5_granen();
            cracotte_naturel();
            cracotte_volkoren();

            liga_babybiscuits();

            liga_crunchy_muesli_appel();
            liga_crunchy_muesli_chocolade();
            liga_crunchy_muesli_rozijnen();
            liga_evergreen_appel();
            liga_evergreen_bosvruchten();
            liga_evergreen_krenten();

            liga_haverkick_appel();
            liga_haverkick_bosvruchten();

            liga_haverrepen_honing();
            liga_haverrepen_kokos();

            liga_kinderbiscuits();

            liga_milkbreak_bosvruchten_framboos();
            liga_milkbreak_melk_aardbei();
            liga_milkbreak_melk_choco();
            liga_milkbreak_melk();

            liga_proteinereep_cranberry();
            liga_proteinereep_pinda();

            vitalu_meergranen();
            vitalu_volkoren_tarwemeel();
            vitalu_volkoren();

            liga_noten_granenrepen();
            liga_notenrepen();






            /// frames
            function frames() {
                let section = $('.frames');

                if (!section.length) {
                    return;
                }

                let
                    tl = new TimelineMax({delay: .2}).timeScale(1),
                    frames = gsap.utils.toArray('.visual-element');

                // Set
                TweenMax.set(frames.slice(1), {
                    opacity: 0
                });

                frames.forEach(el => {
                    // console.log($(el).index());
                    // console.log(frames.length - 1);

                    tl
                        .to(el,
                            {
                                keyframes: [
                                    {
                                        duration: 0,
                                        opacity: 1
                                    },
                                    {
                                        duration: 0,
                                        opacity: 0,
                                        delay: .5
                                    }
                                ]
                            }

                        );
                    if($(el).index() == frames.length - 1){
                        tl
                            .set(el,
                                {
                                    opacity: 1
                                })
                    }

                });

            }


            frames();





        }

        tween();
    }
}

new BrandsAnimations();