import $ from "jquery";
import {TimelineMax, TweenMax, gsap, Power2} from 'gsap';
import { EasePack } from "gsap/EasePack";

gsap.registerPlugin(EasePack);

let imgChangerWelcome =  $('.welcome-img');

if(imgChangerWelcome.length > 0){
    let path;
    if (location.hostname === "localhost" || location.hostname === "192.168.0.108" || location.hostname === "" || location.hostname === "192.168.0.110"){
        path = ''
    } else {
        path = '/wp-content/themes/liga/'
    }



    gsap.timeline({ repeat:-1, repeatDelay:0, yoyo:true})
    .to('.m', {duration:(i)=>[0.8,1.3][i], y:-10266, ease:'steps(29)', stagger:-0.3, onStart: swapImg}, 0)
    .to('.frog', {duration:3, scale:1.018, transformOrigin:'50% 50%', ease:'power2', onComplete:swapMask}, 0)

    let currentMask = 1;
    function swapMask(){
        if (currentMask==3) currentMask = 1;
        else currentMask++;
        gsap.set('.m', {attr:{'xlink:href':path+'assets/img/mask/liquidMask'+currentMask+'.svg'}})
    }

    let currentImg = 1;
    function swapImg(){
        if (currentImg==1) currentImg = 0;
        else currentImg++;
        gsap.set('.frog', {attr:{'xlink:href':path+'assets/img/welcome/welcome-'+currentImg+'.jpg'}})
    }

}


