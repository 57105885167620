import $ from "jquery";
import fullpage from 'fullpage.js';
import imagesLoaded from 'imagesloaded';
import {TimelineMax, TweenMax, Circ, Power3} from 'gsap';

(function () {
    class HarmonySlider {
        constructor() {
            let slider = this;
            this.el = document.getElementById('harmonySlider');

            if (!this.el) {
                return;
            }

            this.slides = document.querySelectorAll('.harmony-slide');
            this.logos = document.querySelectorAll('.harmony-f-logo__img img');
            this.decorTimelines = [];
            this.textTimelines = [];

            const logoWrap = document.querySelector('.harmony-f-logo__img');
            const logoTl = new TimelineMax();

            imagesLoaded(slider.el, function () {
                logoTl.from(logoWrap, 0.8, {
                    scale: 0,
                    ease: Power3.easeOut
                });

                slider.init();
            });
        }

        reverseAnimation(index) {
            let logo = this.logos[index - 1];

            if (logo) {
                TweenMax.to(logo, 0.2, {
                    autoAlpha: 0
                });
            }

            let tl = this.decorTimelines[index - 1];

            if (tl) {
                tl.pause();
                tl.timeScale(2);
                tl.reverse();
            }
        }

        runAnimation(index) {
            let logo = this.logos[index - 1];

            if (logo) {
                TweenMax.to(logo, 0.2, {
                    autoAlpha: 1
                });
            }

            let tl = this.decorTimelines[index - 1];
            if (tl) {
                tl.progress(0);
                tl.timeScale(1);
                tl.play();
                this.textTimelines[index - 1].play();
            }
        }

        prepareAnimation() {
            let timelineOptions = {
                paused: true
            };
            let delay = 0.3;

            let decor = document.querySelectorAll('.harmony-f-logo .decor');
            let text = document.querySelectorAll('.harmony-info');

            TweenMax.set(decor, {
                autoAlpha: 0
            });
            TweenMax.set(text, {
                autoAlpha: 0,
                x: '40%'
            });

            // Harmony
            let harmonyTl = new TimelineMax(timelineOptions);

            // Climat
            let climatDecor = document.querySelectorAll('.harmony-decor-climat .decor');
            let climatTl = new TimelineMax(timelineOptions);

            climatTl
                .to(climatDecor, 0.2, {
                    autoAlpha: 1
                }, delay);

            $(climatDecor).each(function (index, element) {
                climatTl.from(element, 0.5, {
                    xPercent: -50,
                    yPercent: -50,
                    left: '50%',
                    top: '50%',
                    ease: Power3.easeOut
                }, delay + index * 0.02 + 0.05);
            });

            // Bees
            let beesDecorEl = document.querySelectorAll('.harmony-decor-bees .decor-el');
            let beesDecorBase = document.querySelectorAll('.harmony-decor-bees .decor-base');
            let beesTl = new TimelineMax(timelineOptions);

            beesTl
                .to([beesDecorEl, beesDecorBase], 0.2, {
                    autoAlpha: 1
                }, delay)
                .from(beesDecorBase, 0.5, {
                    scale: 0.8
                }, '-=0.2');

            $(beesDecorEl).each(function (index, element) {
                beesTl.from(element, 0.5, {
                    xPercent: -50,
                    yPercent: -50,
                    left: '50%',
                    top: '50%',
                    ease: Power3.easeOut,
                }, delay + index * 0.02 + 0.05);
            });

            // Farmers
            let farmersDecorEl = document.querySelectorAll('.harmony-decor-farmers .decor-el');
            let farmersDecorBase = document.querySelectorAll('.harmony-decor-farmers .decor-base');
            let farmersTl = new TimelineMax(timelineOptions);

            farmersTl
                .to([farmersDecorEl, farmersDecorBase], 0.2, {
                    autoAlpha: 1
                }, delay)
                .from(farmersDecorBase, 0.4, {
                    xPercent: -50,
                    yPercent: -50,
                    left: '50%',
                    top: '50%',
                    ease: Power3.easeOut
                }, '-=0.2');

            $(farmersDecorEl).each(function (index, element) {
                farmersTl.from(element, 0.5, {
                    xPercent: -50,
                    yPercent: -50,
                    left: '50%',
                    top: '50%',
                    ease: Power3.easeOut
                }, delay + index * 0.02 + 0.05);
            });

            // Ground
            let groundDecorEl = document.querySelectorAll('.harmony-decor-ground .decor-el');
            let groundDecorBase = document.querySelectorAll('.harmony-decor-ground .decor-base');
            let groundTl = new TimelineMax(timelineOptions);

            groundTl.to([groundDecorEl], 0.2, {
                autoAlpha: 1
            }, delay);

            $(groundDecorEl).each(function (index, element) {
                groundTl.from(element, 0.5, {
                    xPercent: -50,
                    yPercent: -50,
                    left: '50%',
                    top: '50%',
                    ease: Power3.easeOut
                }, delay + index * 0.03);
            });
            groundTl.from(groundDecorBase, 0.3, {
                scale: 0.9
            }, '-=0.2');

            groundTl.to(groundDecorBase, 0.2, {
                autoAlpha: 1
            }, '-=0.2');

            // Water
            let waterDecorEl = document.querySelectorAll('.harmony-decor-water .decor-el');
            let waterDecorBase = document.querySelectorAll('.harmony-decor-water .decor-base');
            let waterTl = new TimelineMax(timelineOptions);

            waterTl
                .to([waterDecorEl, waterDecorBase], 0.2, {
                    autoAlpha: 1
                }, delay)
                .from(waterDecorBase[0], 0.4, {
                    scale: 0.8
                }, '-=0.2');

            $(waterDecorEl).each(function (index, element) {
                waterTl.from(element, 0.5, {
                    xPercent: -50,
                    yPercent: -50,
                    left: '50%',
                    top: '50%',
                    ease: Power3.easeOut
                }, delay + index * 0.02 + 0.05);
            });

            waterTl
                .from(waterDecorBase[1], 0.3, {
                    scale: 0.8
                }, '-=0.3');

            this.addTimeline(harmonyTl, text[0], true);
            this.addTimeline(climatTl, text[1]);
            this.addTimeline(beesTl, text[2]);
            this.addTimeline(farmersTl, text[3]);
            this.addTimeline(groundTl, text[4]);
            this.addTimeline(waterTl, text[5]);
        }

        addTimeline(decorTl, elements) {
            let textTl = new TimelineMax({
                paused: true
            }).to(elements, 0.6, {
                x: '0%',
                autoAlpha: 1,
                delay: 0.9,
                ease: Power3.easeOut
            });
            this.decorTimelines.push(decorTl);
            this.textTimelines.push(textTl);
        }

        init() {
            let slider = this;
            let animationsOn = window.innerWidth > 1199;

            if (animationsOn) {
                this.prepareAnimation();
            }

            $('#harmonySlider').fullpage({
                sectionSelector: '.harmony-slide',
                menu: '#harmonyPagination',
                anchors: ['harmony', 'climat', 'bees', 'farmers', 'ground', 'water'],
                css3: false,
                easing: 'easeInOutCubic',
                scrollingSpeed: 1000,
                recordHistory: false,
                onLeave: function (origin, destination, direction) {
                    let lastSlide =
                        (direction === 'down' && destination === 7) ||
                        (direction === 'up' && destination === 6);

                    slider.el.classList.toggle('last-slide', lastSlide);

                    if (animationsOn) {

                        if (!lastSlide) {
                            slider.reverseAnimation(origin);
                            slider.runAnimation(destination);
                        }

                        if (direction === 'up' && origin === 7 && destination < 6) {
                            slider.reverseAnimation(6);
                        }
                    }
                },
                afterRender: function () {
                    let hash = window.location.hash;
                    if (!hash || hash === '#harmony' || hash === '#undefined') {
                        let el = document.querySelector('[data-slide-name="harmony"] .harmony-info');
                        TweenMax.to(el, 0.6, {
                            x: '0%',
                            autoAlpha: 1,
                            delay: 0.4,
                            ease: Power3.easeOut
                        });
                    }
                }
            });
        }
    }

    new HarmonySlider();
})();
